import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { MessageBox } from '../message-box/message-box';
import { theme } from '../../themes/theme';
import { Spacer } from '../spacer';
import { MessageList } from '../message-list/message-list';
import classes from './App.module.css';
import { services } from '../../Services/services';
import PreLoader from '../PreLoader/pre-loader';
import {
  ADDRESS_IS_REQUIRED,
  ANIMAL_SERVICE_INTENT,
  BALTIMORE_ILLEGAL_DUMPING_INTENT,
  ANSWER_IS_REQUIRED,
  ASK_NEW_ISSUE_MESSAGE,
  BALTIMORE,
  CANCEL,
  CANNOT_REGISTER_PROBLEM,
  CONTACT_311_MESSAGE,
  CONTACT_911_FOR_FLOODING,
  CONTACT_MAYOR,
  CONTINUE_CONVERSATION_GREETING,
  CONTINUE_CONVERSATION_MESSAGE,
  BALT_ADDRESS_VALIDATION_ERROR,
  CITIBOT_ADDRESS_ERROR_MSG,
  COVID_LARGE_GATHERING,
  COVID_NON_COMPLIANCE_INTENT,
  COVID_VACCINE_INTENT,
  DEFINITELY,
  EMAIL_ADDRESS_IS_REQUIRED,
  EMERGENCY_CONTACT_911,
  EMERGENCY_INTENT,
  END_CONVERSATION_MESSAGE,
  END_CONVERSATION_THANKS_MESSAGE,
  ENTER_VALID_ADDRESS,
  ENTER_VALID_EMAIL,
  ENTER_VALID_EMAIL_ADDRESS,
  ENTER_VALID_PHONE_NUMBER,
  FORGET_IT,
  HELLO_INTENT,
  HELLO_INTENT_PHRASE,
  HELLO_INTENT_RESPONSE,
  HURRICANE_IDA_INTENT,
  IS_LARGE_GATHERING_SLOT,
  IS_PRIVATE_PROPERTY,
  IS_STREET_FLOODED,
  ISSUE_HELP_MESSAGE,
  ISSUE_HELP_MESSAGE_COMPACT,
  MESSAGE_INTENT,
  MORE_THAN_FOUR_UNITS,
  MORE_THAN_FOUR_UNITS_IN_PROPERTY,
  NEVER_MIND,
  NEVERMIND,
  NEW_CONVERSATION_MESSAGE,
  NEW_ORLEANS,
  NO,
  NO_THANK_YOU,
  NO_THANKS,
  NO_THANKYOU,
  NOLA_FLOOD_INTENT,
  NOLA_MAYOR_INTENT,
  NOLA_PEST_INTENT,
  NOLA_TREE_INTENT,
  NOLA_TREE_SERVICE_TYPE,
  NOLA_WASTE_CONTAINER_ORDER_RESPONSE,
  NOTHANKS,
  NOTHANKYOU,
  OAKLAND,
  OAKLAND_ABANDONED_VEHICLE_INTENT,
  OAKLAND_ABANDONED_VEHICLE_MESSAGE,
  OAKLAND_BROKEN_SIDEWALK_INTENT,
  OAKLAND_POTHOLE_INTENT,
  OAKLAND_REPHRASE_PROBLEM_MESSAGE,
  OAKLAND_REQUEST_SUBMITTED_MESSAGE,
  OAKLAND_SEARCH_RESULT_READY_MESSAGE,
  OAKLAND_SUBMIT_SR_CONFIRMATION_MESSAGE,
  OAKLAND_TALLGRASS_INTENT,
  OAKLAND_VISIT_LINK_MESSAGE,
  OAKLAND_YARD_UPKEEP_INTENT,
  PARKING_PERMIT_INTENT,
  PHONE_IS_REQUIRED,
  PUBLIC_PROPERTY_INTENT,
  QUIT,
  REPHRASE_PROBLEM_MESSAGE,
  REQUEST_NOT_SUBMITTED_MESSAGE,
  REQUEST_SUBMITTED_MESSAGE,
  ROSEVILLE_SEARCH_INTENT,
  SEARCH_INTENT,
  SEARCH_QUESTION,
  SEARCH_RESULT_READY_MESSAGE,
  SLOT_IS_BULK,
  SLOT_USER_MESSAGE,
  START_NEW_CONVERSATION_MESSAGE,
  START_OVER,
  STOP,
  SUBMIT_MESSAGE_CONFIRMATION_MESSAGE,
  SUBMIT_SR_CONFIRMATION_MESSAGE,
  SURE,
  THANK_YOU,
  THANK_YOU_INTENT,
  THANKS,
  THANKYOU,
  TREE_BLOCKING_FOLLOWING,
  TREE_ON_POWER_LINE,
  VISIT_LINK_MESSAGE,
  WASTE_CONTAIER_INTENT,
  WASTE_CONTAINER_REPORT_SUBMITTED,
  WASTE_CONTAINER_REQUEST_SUBMITTED,
  WASTE_PICKUP_INTENT,
  WASTE_SERVICE_INTENT,
  WEBCHAT_HEADER,
  WEHO_SCOOTER_INTENT,
  WEHO_SCOOTER_INTENT_MESSAGE,
  WELCOME_MESSAGE,
  WEST_HOLLYWOOD,
  YA_SURE,
  YES,
  YES_SURE,
  BALT_IS_SEE_DUMPING,
  BALT_IS_SEE_INDIVIDUAL,
  BALT_IS_VEHICLE_INVOLVED,
  BALT_VEHICLE_MAKER,
  CITIBOT_SUCCESS_MSG,
  BALT_RODENT_QUESTION,
  BALT_GRASS_MOWING_HCDSPROP_INTENT,
  BALT_TRAFFIC_SIGNAL_TRSIWATI_INTENT,
  BALT_SIGNAL_TYPE_QUESTION,
  BALT_WATER_COMING_FROM_QUESTION,
  BALT_RAINING_QUESTION,
  BALT_ILLEGALY_PARKED_QUESTION,
  BALT_VEHICLE_LOCATION,
  BALT_STREET_LIGHT_QUESTION,
  ROSEVILLE,
  ROSEVILLE_RESIDENTIAL_PARKING_PREMIT_INTENT,
  ROSEVILLE_HOME_OWNER_QUES,
  ROSEVILLE_ADDRESS_VALIDATION_ERROR,
  BALT_LIGHT_PROB,
  BALT_POTHOLE_LOC,
  BALT_POTHOLE_ISSUE,
  BALT_HAZARDOUS_CONDITION_QUES,
  BALT_IS_ANYONE_INJURED_QUES,
  BALT_TRASH_CAN_REQ_QUES,
  BALT_TRASH_CAN_LOC_QUES,
  BALT_PARK_TRASH_CAN_CONCERN_QUES,
  BALT_PARK_MAINTENANCE_QUES,
  BALT_PLANNED_ACTIVITY_SCHEDULED,
  BALT_PERMITTED_EVENT,
  BALT_WHATS_NEED_ATTENTION_QUES,
  BALT_TYPE_OF_TRASH_QUES,
  BALT_TRASH_FROM_VEHICLE,
  BALT_PROPERTY_CAUSING_NOISE,
  BALT_RESTAURANT_OR_BAR_QUES,
  BALTIMORE_NOISE_INTENT,
  ROSEVILLE_SEARCH_RESULT_READY_RES_MSG,
  ColoradoSprings,
  GENERIC_LAG_MESSAGE_RES,
  DENVER,
  ADDRESS_VALIDATION_ERROR,
  COLORADO_ASK_NEW_MESSAGE,
  PERRIS_SEARCH_INTENT,
  IOP_SEARCH_INTENT,
  COCOA_SEARCH_INTENT,
  LACSD_SEARCH_INTENT,
  BAKERSFIELD_PROPERTY_VIOLATION_INTENT,
  BAKERSFIELD,
  ROCHESTER,
  LEWISVILLE,
  LEWISVILLE_VEHICLE_CONCERN_SLOT,
  LEWISVILLE_UNIMPROVED_VEHICLE_SLOT,
  LEWVILLE_POTHOLE_SLOT,
  LEWISVILLE_TRAFFIC_SIGNAL_CONCERN_SLOT,
  LEWISVILLE_TREE_FALLEN_SLOT,
  LEWISVILLE_TRASH_CART_CONCERN_SLOT,
  LEWISVILLE_RECYCLE_LOC_SLOT,
  LEWISVILLE_RECYCLE_ISSUE_SLOT,
  LEWISVILLE_TRASH_CART_LOC_SLOT,
  LEWISVILLE_TRASH_CART_RESIDENTIAL_LOC_ISSUE_SLOT,
  ROCHESTER_IS_MN_PLATE_SLOT,
  ROCHESTER_WHAT_STATE_SLOT,
  FLORENCE
} from '../../utils/messages';
import { surveyMessages } from '../../utils/surveyMessages';
import {
  CSSucessDyanmicMessage,
  SuccessDynamicMessage,
  formatIntent,
  formatSearchResult,
  getCovidSearchResponse,
  getDynamicMessage,
  getMessageTime,
  getNOLAMayorRequestLink,
  getWeHoScooterIntentLink,
  hasParkingPermitIntentFlowLinks,
  isNoUtteranceMessage,
  isYesUtterance,
  oaklandYardUpkeepLinks,
  rephraseDyanmicMessage,
  rosevilleDynamicMessage,
} from '../../utils/helper.util';
import { Box } from '@material-ui/core';
import {
  getNolaFloodIntentQuestions,
  getNolaPropertyAndDamageTypeNullQuestions,
  getNolaTreeIntentQuestions,
  getNolaWastePickupQuestions,
  getNolaWasterContainerQuestions,
  getNolaWasteServiceQuestions,
  getParkingPermitIntentFlowQuestions,
} from '../../utils/mappings';
import { joinStrings, splitStrings } from '../../utils/string.util';
import { AxiosResponse } from 'axios';
import moment from 'moment';

require('dotenv').config();

const appLanguageCode = 'en'

const isSearchIntent = (intent:string) => {
  switch(intent) {
    case SEARCH_INTENT:
    case ROSEVILLE_SEARCH_INTENT:
    case PERRIS_SEARCH_INTENT:
    case IOP_SEARCH_INTENT:
    case COCOA_SEARCH_INTENT:
    case LACSD_SEARCH_INTENT:
      return true
    default:
      return false
  }
}
class App extends Component {
  welcomeMessage = WELCOME_MESSAGE;
  state = {
    messages: [
      {
        title: this.welcomeMessage,
        isBot: 1,
        time: getMessageTime(),
      },
    ],
    customMessages: [] as any,
    accountGreetingMessages: [] as any,
    rephraseMessages: [] as any,
    searchResponseArr: [] as any,
    emergencyIntentResArr: [] as any,
    questionsList: [],
    originalQuestionsList: [], // This is for Baltimore
    intentQuestionsList: [],
    surveyList: [],
    questionNo: -2,
    questionCount: 0,
    type: '',
    accountGreeting: false,
    accountGreetingMessage: '',
    logo_url: '',
    message_icon: '',
    phone_number: '',
    city_name: '',
    account_city: '',
    account_region: '',
    custom_account_intents: {} as any,
    intentCanBeAnonymous: {} as any,
    customResponses: {} as any,
    confirmationMessageResponse: {} as any,
    isGtp3Enabled: false,
    theme: '#48c3e3',
    submittingRequest: false,
    isMessageButtonEnabled: false,
    showMessageButton: false,
    sourceLanguageCode: appLanguageCode,
    content: {} as any,
    responseMessage: '',
    removeMessageButton: false, // This is for Roseville
    lagMessage: '',
    hideLagMessage: false,
    scoutEnabled: false,
    doubleCheckName: false,
    botRephraseResponse: '',
    crmFailMessage: '',
    multilingualEnabled: false,
    delayedTime: 0,
    botSearchIntent: '',
    endMessage:'',
    SRConfirmationMessage: '',
    MessageIntentSubmissionResponse: '',
    SRRejectionMessage: '',
    crmSuccessMessage: '',
    allowNoEmail: false,
    emailValidationMessage: '',
    removeMessageButtonPhrase: '',
    followUpMessage: '',
    feedbackContent: '',
  };

  componentDidMount(): void {
    const url_parms = window.location.search;
    const urlParams = new URLSearchParams(url_parms);
    this.id = parseInt(urlParams.get('org_id')!);

    if (!this.state.accountGreeting) {
      services
        .getCitibotAccount(this.id)
        .then((response) => {
          const cityAccount = response.data as any;
          const account = cityAccount.account || {};
          const {
            content,
            city,
            state,
            gtp3_config,
            bot,
            intentCanBeAnonymous,
            removeMessageButton,
            scoutEnabled,
            lagMessage,
            hideLagMessage,
            delayedTime,
            multilingualEnabled,
            botSearchIntent,
            doubleCheckName,
            endMessage,
            allowNoEmail
          } = account;
          const organisation = cityAccount.organisation;
          this.welcomeMessage = content?.webGreeting
            ? content.webGreeting
            : content?.greeting ? content.greeting : WELCOME_MESSAGE;
          this.welcomeMessage = this.welcomeMessage
            .trim()
            .replace(/^\s+|\s+$/g, '');
          const newMessage = this.welcomeMessage;
          const newState: Record<string,any> = {
            ...this.state,
            messages: [
              {
                title: newMessage,
                isBot: 1,
                time: getMessageTime(),
              },
            ],
            accountGreeting: true,
            account_city: city,
            account_region: state,
            accountGreetingMessage: newMessage,
            isGtp3Enabled: gtp3_config && gtp3_config.gtp3Enabled
          }
          if(endMessage){
            newState.endMessage=endMessage.trim()
          }
          if (
            bot &&
            bot.customization &&
            bot.customization.intents
          ) {
            newState.custom_account_intents = bot.customization.intents
          }

          if (content) {
            newState.content = content

            if (content.customResponses) {
              newState.customResponses = content.customResponses
            }

            if (content.confirmationMessageResponse) {
              newState.confirmationMessageResponse = content.confirmationMessageResponse
            }

            // Bot's Ask Issue again rephrase response
            if (content.botRephraseResponse) {
              newState.botRephraseResponse = content.botRephraseResponse
            }

            //Bot's CRM Fail Message response
            if (content.crmFailMessage) {
              newState.crmFailMessage = content.crmFailMessage
            }
            //SR confirmation message
            if (content.SRConfirmationMessage) {
              newState.SRConfirmationMessage = content.SRConfirmationMessage
            }
            //Message intent confirmation message
            if (content.MessageIntentSubmissionResponse) {
              newState.MessageIntentSubmissionResponse = content.MessageIntentSubmissionResponse
            }
            //Handle Rejection message when user type no to confirmation message
            if (content.SRRejectionMessage) {
              newState.SRRejectionMessage = content.SRRejectionMessage
            }
            //CRM Success Message
            if (content.crmSuccessMessage) {
              newState.crmSuccessMessage = content.crmSuccessMessage
            }
            /**This is for VH, the moment enter wrong email twice, need to throw this message
             * and assigning a noreply email
             */
            if (content.emailValidationMessage) {
              newState.emailValidationMessage = content.emailValidationMessage
            }
            /**This is for removing message button phrases if removeMessageButton is enabled */
            if (content.removeMessageButtonPhrase) {
              newState.removeMessageButtonPhrase = content.removeMessageButtonPhrase
            }
            /**This is for overriding the follow up message */
            if (content.followUpMessage) {
              newState.followUpMessage = content.followUpMessage
            }
             /**This is for showing feedback form content */
             if (content.feedbackContent) {
              newState.feedbackContent = content.feedbackContent
            }
          }

          if (intentCanBeAnonymous) {
            newState.intentCanBeAnonymous = intentCanBeAnonymous
          }

          // For Roseville
          if (removeMessageButton) {
            newState.removeMessageButton = removeMessageButton
          }

          // ScoutEnabled from account doc
          if (scoutEnabled) {
            newState.scoutEnabled = scoutEnabled
          }

          // If double check name enabled from doc or not
          if (doubleCheckName) {
            newState.doubleCheckName = doubleCheckName
          }

          // This is delayed message if scout takes more than 16 seconds
          if (lagMessage) {
            newState.lagMessage = lagMessage
          }

          // This flag decides whether to show the lag message or not
          if (hideLagMessage){
            newState.hideLagMessage = hideLagMessage
          }

          //This meant for sending a noreply email when user types the wrong email twice
          if (allowNoEmail) {
            newState.allowNoEmail = allowNoEmail
          }

          if (delayedTime) {
            newState.delayedTime = delayedTime
          }

          if (multilingualEnabled) {
            newState.multilingualEnabled = multilingualEnabled
          }

          if (botSearchIntent) {
            newState.botSearchIntent = botSearchIntent
          }

          if (organisation.theme) {
            newState.theme = organisation.theme
          }
          if (organisation.enable_message_button) {
            newState.isMessageButtonEnabled = organisation.enable_message_button
          }
          if (organisation.logo) {
            newState.logo_url = organisation.logo
            this.image_url = organisation.logo;
          }
          if (organisation.message_icon) {
            newState.message_icon = organisation.message_icon
          }
          if (organisation.phone_number) {
            newState.phone_number = organisation.phone_number
            this.phone_number = organisation.phone_number;
          }
          const chatHeaderName = organisation.name || WEBCHAT_HEADER
          newState.city_name = chatHeaderName
          this.chatHeader = chatHeaderName;
          this.setState(newState);
        })
        .catch((err) => {
          console.error('Error calling API to fetch account');
          this.setState({
            ...this.state,
            messages: [
              {
                title: WELCOME_MESSAGE,
                isBot: 1,
                time: getMessageTime(),
              },
            ],
            accountGreeting: true,
            accountGreetingMessage: WELCOME_MESSAGE
          });
        });
    }
  }

  phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  chat_space: any = React.createRef();
  // Regular expression for URL
  // expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  expression = /(https?:\/\/(?:www\.|(?!www))[^\s]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  urlRegexExpression = /https?:\/\/[\S]*/g;
  dialogHistory: {userInput:string;citibot:string}[] = [];
  slots: any = {};
  cancelRequest = false;
  firstUserReply: string = '';
  loader: boolean = false;
  lastQues: boolean = false;
  showConfirmationMessage: boolean = false;
  isConfirmationRejected: boolean = false;
  requestConfirmed: boolean = false;
  showConfirmSubmissionMessage: boolean = false;
  continueConvQues: boolean = false;
  link: boolean = false;
  haslinks: boolean = false;
  contactNo: any = '';
  intentId: number = 0;
  currentIntent: string = '';
  id: number = 1;
  image_url: any = '/citibot.png';
  chatHeader: string = 'CITIBOT';
  message_url: string = '';
  phone_number: number = 18432121210;
  search: boolean = false;
  searchString: string = '';
  issueAddress: string = '';
  searchResponse: { title: string, link: string }[] = [];
  searchResponseList: any = {};
  isMobileDevice = false;
  validateAddress = 0;
  validatePhoneNumber = 0;
  validateEmailAddress = 0;
  validateRoseVilleDate = 0;
  validateQuestion = 0;
  endConversation = false;
  createOtherTypeRequest = false;
  finalLink = '';
  takeSurvey = false;
  startSurvey = false;
  showstartSurveyOption = false;
  currentSurveyQuestion = 0;
  totalSurveyQuestions = 0;
  surveyData: any[] = [];

  static disableMessageButton: boolean = false;

  generateRandomUser = (length: number) => {
    const chars: string =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  /**
   * Checks Whether the first reply is a phone number of the user query.
   */
  checkFirstUserReply = () => {
    // const regExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
    const regExp =
      /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s]?[\0-9]{3}[\s]?[0-9]{4}$/;
    const number = this.contactNo.match(regExp);
    if (number && number[0]) {
      let num = number[0] + '';
      if (num.charAt(0) !== '1') {
        num = '1' + num;
      }
      this.contactNo = num;
    } else {
      // this.contactNo = "";
      const replyNotNum = isNaN(this.contactNo);
      if (replyNotNum) {
        this.firstUserReply = this.contactNo;
        this.contactNo = this.generateRandomUser(11);
      }
    }
  };

  checkPhoneNumberValidity = (phoneNumber: string) => {
    phoneNumber = phoneNumber.replace(/[- )(]/g, '');

    // const regExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
    const regExp =
      /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s]?[\0-9]{3}[\s]?[0-9]{4}$/;
    const number = phoneNumber.match(regExp);
    if (number && number[0]) {
      let num = number[0] + '';
      if (num.charAt(0) !== '1') {
        num = '1' + num;
      }
      return num;
    } else {
      return undefined;
    }
  };

  getGreetingUrlMessage = (message: string) => {
    const URL_REGEX =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    const words = message.split(' ');
    return words.map((word) => {
      return word.match(URL_REGEX) ? (
        <>
          <a
            href={word}
            style={{color: 'white'}}
            target="_blank"
            rel="noopener noreferrer"
          >
            {word}
          </a>{' '}
        </>
      ) : (
        word + ' '
      );
    });
  };

  recognizeSpecialMessages = (message: string) => {
    return (
      message === THANK_YOU ||
      message === THANKS ||
      message === THANKYOU ||
      message === NEVER_MIND ||
      message === NEVERMIND ||
      message === CANCEL ||
      message === START_OVER ||
      message === QUIT ||
      message === STOP ||
      message === FORGET_IT
    );
  };

  checkIsMobileDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobileDevice = true;
    }
  };

  feedBackContent = (
    title: string,
    message?: string
  ) => {
    if (message) {
      this.state.messages.push({
        title: message,
        isBot: 0,
        time: getMessageTime(),
      });
    }
    this.state.messages.push({
      title: title,
      isBot: 1,
      time: getMessageTime(),
    });
    this.setState({...this.state, submittingRequest: false, questionNo: -1});
    this.state.intentQuestionsList.splice(
      0,
      this.state.intentQuestionsList.length
    );
    this.setState({...this.state}, () => {
      this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
    });
  }

  restartConversation = (
    title: string,
    message: string | undefined = undefined
  ) => {
    console.log('restartConversation title ',title, ' message', message)
    this.loader = false;
    if (message) {
      this.state.messages.push({
        title: message,
        isBot: 0,
        time: getMessageTime(),
      });
    }
    this.state.messages.push({
      title: title,
      isBot: 1,
      time: getMessageTime(),
    });
    // so this can only be work for fayettville's sorry message
    if ((this.state.botRephraseResponse.includes('http') || this.state.botRephraseResponse.includes('https')) && this.state.botRephraseResponse.includes(title)) {
      let rephraseResponse: { title: string, link: string }[] = [];
      const {searchResponse} = rephraseDyanmicMessage(this.state.botRephraseResponse);
      rephraseResponse = searchResponse;
      this.haslinks = true;
      this.link = true;
      this.searchResponseList[this.state.messages.length - 1] = rephraseResponse;
    }
    this.setState({...this.state, submittingRequest: false, questionNo: -1});
    this.state.intentQuestionsList.splice(
      0,
      this.state.intentQuestionsList.length
    );
    this.setState({...this.state}, () => {
      this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
    });
  };

  thanksResponse = (title: string) => {
    this.dialogHistory = [];
    this.slots = {};
    this.resetConfirmationState();
    services
      .getIntent(this.contactNo, this.phone_number, CANCEL)
      .then((res) => {
        this.restartConversation(title);
      })
      .catch((err) => {
        this.restartConversation(title);
      });
  };

  askIssueAgain = async () => {
    this.setState({...this.state, showMessageButton: true});
    App.disableMessageButton = false;
    let rephraseMessageResponse = '';
    if (this.state.account_city.toLowerCase() === OAKLAND) {
      rephraseMessageResponse = OAKLAND_REPHRASE_PROBLEM_MESSAGE;
    } else if (this.state.botRephraseResponse) {
      const { message } = rephraseDyanmicMessage(this.state.botRephraseResponse);
      rephraseMessageResponse = message;
    }
    else {
      rephraseMessageResponse = REPHRASE_PROBLEM_MESSAGE;
    }
    if ((rephraseMessageResponse.toLowerCase().includes('sorry') || rephraseMessageResponse.toLowerCase().includes('error')) && this.state.account_city.toLowerCase() === DENVER) {
      this.state.removeMessageButton = false;
    }
    this.state.rephraseMessages.push(rephraseMessageResponse);
    if (this.state.sourceLanguageCode !== appLanguageCode) {
      rephraseMessageResponse = await services.translateText(rephraseMessageResponse, this.state.sourceLanguageCode)
    }
    this.thanksResponse(rephraseMessageResponse);
  };

  resetConfirmationState = () => {
    this.showConfirmationMessage = false;
    this.isConfirmationRejected = false;
    this.requestConfirmed = false;
    this.showConfirmSubmissionMessage = false;
    this.createOtherTypeRequest = false;
    this.validateEmailAddress = 0;
    this.validateAddress = 0;
    this.validatePhoneNumber = 0;
    this.validateRoseVilleDate = 0;
    this.validateQuestion = 0;
  };

  handleSubmitConfirmation = async(message: string) => {
    this.showConfirmSubmissionMessage = true;
    this.slots[this.state.questionsList[this.state.questionNo - 1]['slot']] =
      message;

    if (this.currentIntent === MESSAGE_INTENT) {
      let submitConfirmationResponse = SUBMIT_MESSAGE_CONFIRMATION_MESSAGE;
      if (this.state.MessageIntentSubmissionResponse) {
        submitConfirmationResponse = this.state.MessageIntentSubmissionResponse;
      }
      if(this.state.sourceLanguageCode !== appLanguageCode) {
        submitConfirmationResponse = await services.translateText(submitConfirmationResponse, this.state.sourceLanguageCode)
      }
      this.state.messages.push({
        title: submitConfirmationResponse,
        isBot: 1,
        time: getMessageTime(),
      });
    } else {
      let message = '';
      let dynamicMessageBody = (
        this.currentIntent === OAKLAND_YARD_UPKEEP_INTENT ||
        this.currentIntent === OAKLAND_BROKEN_SIDEWALK_INTENT ||
        this.currentIntent === OAKLAND_TALLGRASS_INTENT ||
        this.currentIntent === OAKLAND_POTHOLE_INTENT ||
        this.currentIntent === OAKLAND_ABANDONED_VEHICLE_INTENT
      ) ? OAKLAND_SUBMIT_SR_CONFIRMATION_MESSAGE : SUBMIT_SR_CONFIRMATION_MESSAGE
      if (this.state.SRConfirmationMessage) {
        dynamicMessageBody = this.state.SRConfirmationMessage;
      }
      message = getDynamicMessage(
        dynamicMessageBody,
        formatIntent(this.currentIntent, this.slots, this.firstUserReply),
        this.slots['address'] ? this.slots['address'] : null
      );
      if(this.state.sourceLanguageCode !== appLanguageCode) {
        message = await services.translateText(message, this.state.sourceLanguageCode)
      }
      console.log('529 message =>',message)
      this.state.messages.push({
        title: message,
        isBot: 1,
        time: getMessageTime(),
      });
    }

    this.setState(
      {...this.state, questionNo: -1, intentQuestionsList: []},
      () => {
        this.chat_space.current.scrollTop =
          this.chat_space.current.scrollHeight;
      }
    );
  };

  /*
   * Fetch questions for an intent or fetch search result
   */
  getIntentQuestions = async () => {
    const delayedTime = this.state.delayedTime ? this.state.delayedTime : parseInt(process.env.DELAYEDTIME as string);
    const sourceCode = await services.getTranslateTextCode(this.firstUserReply, 'en');
    if (this.state.rephraseMessages.length >= 1) {
      this.setState({
        ...this.state,
        rephraseMessages: []
      })
    }
    if (this.state.searchResponseArr.length >= 1) {
      this.setState({
        ...this.state,
        searchResponseArr: []
      })
    }
    if (this.state.accountGreetingMessages.length >= 1) {
      this.setState({
        ...this.state,
        accountGreetingMessages: []
      })
    }
    if (this.state.customMessages.length >= 1) {
      this.setState({
        ...this.state,
        customMessages: []
      })
    }
    if (this.state.emergencyIntentResArr.length >= 1) {
      this.setState({
        ...this.state,
        emergencyIntentResArr: []
      })
    }
      setTimeout(async () => {
        if (this.state.scoutEnabled && this.state.intentQuestionsList.length === 0 && this.state.customMessages.length === 0 && 
          this.state.accountGreetingMessages.length === 0 && this.state.rephraseMessages.length === 0 &&
          this.state.searchResponseArr.length === 0  && this.state.emergencyIntentResArr.length === 0) {
            if(!this.state.hideLagMessage){
              const delayedMessage = `${this.state.lagMessage}` ? `${this.state.lagMessage}` : GENERIC_LAG_MESSAGE_RES
              const translatedText = await services.translateText(delayedMessage, sourceCode);
              this.state.messages.push({
                title: this.state.multilingualEnabled ? translatedText : delayedMessage,
                isBot: 1,
                time: getMessageTime(),
            });
          }
        this.loader = true;
        this.setState({...this.state}, () => {
          this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
        });
      }
    }, delayedTime);
    services
      .getIntent(this.contactNo, this.phone_number, 'cancel')
      .then(async (res) => {
        services
          .getIntent(this.contactNo, this.phone_number, this.firstUserReply)
          .then(async(res: any) => {
            // this.dialogHistory = [];
            console.log('first user text getIntent res =>', res)
            const responseData = res?.data || {}
            const { originalLanguageCode, response, isUsingInterceptor, scoutEnabled } = responseData
            
            this.setState({...this.state, showMessageButton: false, sourceLanguageCode: originalLanguageCode || appLanguageCode, responseMessage: response});
            this.slots = {};
            //This is for Denver is bot returns error or sorry message then only in that case we need to enable the message button
            if ((response.toLowerCase().includes('sorry') || response.toLowerCase().includes('error')) && this.state.account_city.toLowerCase() === DENVER) {
              this.state.removeMessageButton = false;
            }
            if (res.data.slots) {
              const intentSlots = res.data.slots;
              if (intentSlots.propertyType) {
                this.slots['propertyType'] = intentSlots.propertyType;
              }
              if (intentSlots.issueType) {
                this.slots['issueType'] = intentSlots.issueType;
              }
              if (intentSlots.damageType) {
                this.slots['damageType'] = intentSlots.damageType;
              }
              if (intentSlots.wasteType) {
                this.slots['wasteType'] = intentSlots.wasteType;
              }
              if (intentSlots.startStop) {
                this.slots['startStop'] = intentSlots.startStop;
              }
              if (intentSlots.treeServiceType) {
                this.slots['treeServiceType'] = intentSlots.treeServiceType;
              }
              if (intentSlots.pestType) {
                this.slots['pestType'] = intentSlots.pestType;
              }
              if (intentSlots.floodingType) {
                this.slots['floodingType'] = intentSlots.floodingType;
              }
              if (intentSlots.cave_in_phrase) {
                this.slots['cave_in_phrase'] = intentSlots.cave_in_phrase;
              }
              if (intentSlots.permitType) {
                this.slots['permitType'] = intentSlots.permitType;
              }
              if (intentSlots.yard_item) {
                this.slots['yard_item'] = intentSlots.yard_item;
              }
            }

            if (
              res.data.intent === HELLO_INTENT ||
              res.data.intent === HELLO_INTENT_PHRASE
            ) {
              let title = this.state.accountGreetingMessage
                ? this.state.accountGreetingMessage
                : res.data.response.trim() + HELLO_INTENT_RESPONSE
              this.state.accountGreetingMessages.push(title);
              if (this.state.sourceLanguageCode !== appLanguageCode) {
                title = await services.translateText(title, this.state.sourceLanguageCode)
              }
              this.loader = false;
              this.state.messages.push({
                title,
                isBot: 1,
                time: getMessageTime(),
              });
              this.setState({...this.state});
            } else if (res.data.intent === COVID_VACCINE_INTENT) {
              this.loader = false;
              this.search = false;
              this.searchResponse = getCovidSearchResponse(res.data.response);
              this.haslinks = true;
              this.link = true;
              let searchResultReadyResponse = SEARCH_RESULT_READY_MESSAGE

              if (this.state.sourceLanguageCode !== appLanguageCode) {
                searchResultReadyResponse = await services.translateText(searchResultReadyResponse, this.state.sourceLanguageCode)
              }
              this.state.messages.push({
                title: searchResultReadyResponse,
                isBot: 1,
                time: getMessageTime(),
              });

              const messageIndex = this.state.messages.length;
              this.searchResponseList[messageIndex - 1] = this.searchResponse;

              this.setState({...this.state, showMessageButton: true}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });
            } else if (res.data.intent === NOLA_MAYOR_INTENT) {
              this.loader = false;
              this.search = false;
              const {
                title,
                searchResponse: getMayorSearchResponse
              } = getNOLAMayorRequestLink(response);
              this.searchResponse = getMayorSearchResponse;
              this.haslinks = true;
              this.link = true;
              this.state.messages.push({
                title: this.state.sourceLanguageCode === appLanguageCode? CONTACT_MAYOR :  title,
                isBot: 1,
                time: getMessageTime(),
              });

              const messageIndex = this.state.messages.length;
              this.searchResponseList[messageIndex - 1] = this.searchResponse;

              this.setState({...this.state}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });
            } else if (res.data.intent === WEHO_SCOOTER_INTENT) {
              this.currentIntent = res.data.intent;
              this.loader = false;
              this.search = false;
              this.searchResponse = getWeHoScooterIntentLink();
              this.haslinks = true;
              this.link = true;
              const title = this.state.sourceLanguageCode !== appLanguageCode?  await services.translateText(WEHO_SCOOTER_INTENT_MESSAGE, this.state.sourceLanguageCode) : WEHO_SCOOTER_INTENT_MESSAGE;
              this.state.messages.push({
                title,
                isBot: 1,
                time: getMessageTime(),
              });

              const messageIndex = this.state.messages.length;
              this.searchResponseList[messageIndex - 1] = this.searchResponse;

              this.setState({...this.state}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });
            } else if (res.data.intent === EMERGENCY_INTENT) {
              let contactResponse = EMERGENCY_CONTACT_911 ; 
              let newConversationResponse = NEW_CONVERSATION_MESSAGE ; 
              if (originalLanguageCode && originalLanguageCode !== appLanguageCode) {
                const joinedCustomResponse = joinStrings([contactResponse, newConversationResponse])
                const translatedText = await services.translateText(joinedCustomResponse, originalLanguageCode);
                [contactResponse, newConversationResponse] = splitStrings(translatedText as string)
              }
              this.state.emergencyIntentResArr.push(contactResponse, newConversationResponse);
              this.resetConfirmationState();
              this.restartConversation(contactResponse);
              this.restartConversation(newConversationResponse);
            } else if (
              ( this.state.botSearchIntent && this.state.botSearchIntent === res.data.intent  ) || 
              isSearchIntent(res.data.intent)
            ) {
              this.loader = false;
              if (this.state.isGtp3Enabled) {
                this.currentIntent = res.data.intent;
                const respLink = this.urlRegexExpression.exec(
                  res.data.response
                );
                let newConversationResponse = NEW_CONVERSATION_MESSAGE;
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  newConversationResponse = await services.translateText(newConversationResponse, this.state.sourceLanguageCode)
                }
                if (respLink && respLink[0]) {
                  this.loader = false;
                  this.search = false;
                  this.link = true;
                  this.haslinks = true;
                  const title = res.data.response.replace(
                    this.urlRegexExpression,
                    ''
                  );

                  services
                    .getCitibotSearchDocument(respLink[0])
                    .then((res) => {
                      let searchDocResult: any = res.data;
                      if (
                        searchDocResult &&
                        searchDocResult.data &&
                        searchDocResult.data.length
                      ) {
                        searchDocResult = searchDocResult.data;
                        this.searchResponse = searchDocResult.map(
                          (docResult: any) => {
                            return {
                              title: docResult.name,
                              link: docResult.url,
                            };
                          }
                        );
                      } else {
                        this.searchResponse = [
                          {title: 'visit us here', link: respLink[0]},
                        ];
                      }

                      App.disableMessageButton = false;
                      const messageIndex = this.state.messages.length;
                      this.searchResponseList[messageIndex] =
                        this.searchResponse;
                      this.state.messages.push({
                        title: title.trim(),
                        isBot: 1,
                        time: getMessageTime(),
                      });

                      this.setState({
                        ...this.state,
                        showMessageButton: true,
                      });
                      this.resetConfirmationState();
                      this.restartConversation(newConversationResponse);
                      this.firstUserReply = '';
                      return;
                    })
                    .catch((err) => {
                      this.searchResponse = [
                        {title: 'visit us here', link: respLink[0]},
                      ];
                      App.disableMessageButton = false;
                      const messageIndex = this.state.messages.length;
                      this.searchResponseList[messageIndex] =
                        this.searchResponse;
                      this.state.messages.push({
                        title: title.trim(),
                        isBot: 1,
                        time: getMessageTime(),
                      });

                      this.setState({...this.state, showMessageButton: true});
                      this.resetConfirmationState();
                      this.restartConversation(newConversationResponse);
                      this.firstUserReply = '';
                      return;
                    });
                } else {
                  App.disableMessageButton = false;
                  this.setState({...this.state, showMessageButton: true});
                  this.resetConfirmationState();
                  this.restartConversation(res.data.response);
                  this.restartConversation(newConversationResponse);
                  return;
                }
              } else {
                const scrollHeight = this.chat_space.current.scrollHeight - 180;
                this.loader = false;
                this.search = false;
                const searchLinks = res.data.searchResults;
                const formattedSearchLinks = formatSearchResult(searchLinks);
                const data = formattedSearchLinks;
                let feedBackResponse = this.state.feedbackContent;
                let newConversationResponse = NEW_CONVERSATION_MESSAGE;
                if (this.state.followUpMessage) {
                  newConversationResponse = this.state.followUpMessage;
                }
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  [newConversationResponse, feedBackResponse] = await Promise.all([
                    services.translateText(newConversationResponse, this.state.sourceLanguageCode),
                    services.translateText(feedBackResponse, this.state.sourceLanguageCode)
                  ])
                }

                if (!data.length) {
                  // this.lastQues = true;
                  this.haslinks = false;
                  this.searchResponse = data;
                  App.disableMessageButton = false;
                  // let visitLinkResponse = this.state.account_city.toLowerCase() === OAKLAND ? OAKLAND_VISIT_LINK_MESSAGE :VISIT_LINK_MESSAGE;
                  let visitLinkResponse;
                  let searchRephraseResponse: { title: string, link: string }[] = [];
                  if (scoutEnabled) {
                    visitLinkResponse = response;
                  } else {
                    // visitLinkResponse = this.state.account_city.toLowerCase() === OAKLAND ? OAKLAND_VISIT_LINK_MESSAGE :VISIT_LINK_MESSAGE;
                    if (this.state.account_city.toLowerCase() === OAKLAND) {
                      visitLinkResponse = OAKLAND_VISIT_LINK_MESSAGE;
                    } else if (this.state.botRephraseResponse) {
                      const {message, searchResponse} = rephraseDyanmicMessage(this.state.botRephraseResponse);
                      visitLinkResponse = message;
                      if (this.state.botRephraseResponse.includes('http') || this.state.botRephraseResponse.includes('https')) {
                        searchRephraseResponse = searchResponse;
                      }
                    } else if (this.state.account_city.toLowerCase() === ColoradoSprings) {
                      visitLinkResponse = this.state.botRephraseResponse;
                    } 
                    else {
                      visitLinkResponse = VISIT_LINK_MESSAGE;
                    }
                  }
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    visitLinkResponse = await services.translateText(visitLinkResponse, this.state.sourceLanguageCode)
                  }
                  this.state.searchResponseArr.push(visitLinkResponse);
                  this.state.messages.push({
                    title: visitLinkResponse,
                    isBot: 1,
                    time: getMessageTime()
                  });
                  if (searchRephraseResponse.length > 0 && visitLinkResponse) {
                    this.haslinks = true;
                    this.link = true;
                    this.searchResponseList[this.state.messages.length - 1] = searchRephraseResponse;
                  }
                } else {
                  let visitLinkResponse;
                  if (scoutEnabled) {
                    visitLinkResponse = response;
                  } else {
                    if (this.state.account_city.toLowerCase() === OAKLAND) {
                      visitLinkResponse = OAKLAND_SEARCH_RESULT_READY_MESSAGE;
                    } else if (this.state.account_city.toLowerCase() === ROSEVILLE) {
                      visitLinkResponse = ROSEVILLE_SEARCH_RESULT_READY_RES_MSG;
                    } else {
                      visitLinkResponse = SEARCH_RESULT_READY_MESSAGE;
                    }
                  }
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    visitLinkResponse = await services.translateText(visitLinkResponse, this.state.sourceLanguageCode)
                  }
                  this.state.searchResponseArr.push(visitLinkResponse);
                  this.searchResponse = data;
                  this.haslinks = true;
                  App.disableMessageButton = false;
                  const messageIndex = this.state.messages.length;
                  this.searchResponseList[messageIndex] = this.searchResponse;
                  this.state.messages.push({
                    title: visitLinkResponse,
                    isBot: 1,
                    time: getMessageTime()
                  });
                }
                if (feedBackResponse) {
                  this.state.messages.push({
                    title: feedBackResponse,
                    isBot: 1,
                    time: getMessageTime()
                  });
                }
                this.state.messages.push({
                  title: newConversationResponse,
                  isBot: 1,
                  time: getMessageTime()
                });
                this.firstUserReply = '';
                this.setState({...this.state, showMessageButton: true, questionNo: -1}, () => {
                  // this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
                  this.chat_space.current.scrollTop = scrollHeight;
                });
              }
            } else {
              if (res.data.intent === MESSAGE_INTENT) {
                this.currentIntent = res.data.intent;
                if (this.state?.customResponses && Object.keys(this.state.customResponses).length !== 0 && this.state.customResponses[MESSAGE_INTENT]) {
                  let customMessage = this.state.customResponses[MESSAGE_INTENT];
                  this.state.customMessages.push(customMessage);
                  let feedBackResponse = this.state.feedbackContent;
                  let newConversationResponse = NEW_CONVERSATION_MESSAGE;
                  if (this.state.followUpMessage) {
                    newConversationResponse = this.state.followUpMessage;
                  }
                  if (originalLanguageCode && originalLanguageCode !== appLanguageCode) {
                    [customMessage, newConversationResponse, feedBackResponse] = await Promise.all([
                      services.translateText(customMessage, this.state.sourceLanguageCode),
                      services.translateText(newConversationResponse, this.state.sourceLanguageCode),
                      services.translateText(feedBackResponse, this.state.sourceLanguageCode)
                    ]);
                  }
                  console.log(`Custom message response found for ${res.data.intent} => ${customMessage}`);
                  const respLink = this.urlRegexExpression[Symbol.match](customMessage);
                  this.searchResponse = [];
                  if (respLink && respLink[0]) {
                    let title = customMessage;

                    respLink.forEach((subLink, index) => {
                      const messageIndex = this.state.messages.length;
                      let splitTitle = title.split(subLink);
                      let messageTitle = splitTitle[0];
                      title = splitTitle[1].trim();

                      this.searchResponse = [{title: 'visit us here', link: subLink}];
                      this.searchResponseList[messageIndex] = this.searchResponse;

                      if (index + 1 === respLink.length) {
                        this.finalLink = subLink;
                      }

                      this.state.messages.push({
                        title: messageTitle.trim(),
                        isBot: 1,
                        time: getMessageTime()
                      });
                    });
                    this.link = true;
                    this.haslinks = true;
                    this.setState({...this.state});
                  } 
                  this.resetConfirmationState();
                  if(!(respLink && respLink[0])){
                    this.restartConversation(customMessage);
                  }
                  if (feedBackResponse) {
                    this.feedBackContent(feedBackResponse);
                  }
                  this.restartConversation(newConversationResponse);
                  return
                }

                if (
                  !this.expression.test(res.data.response) &&
                  !this.urlRegexExpression.test(res.data.response)
                ) {
                  if (!scoutEnabled) {
                    throw new Error('Unable to understand');
                  }
                }
                this.setState({...this.state, showMessageButton: true});
              }
              console.log("this spot at least");
              const interceptedResponseFlag = (isUsingInterceptor && response);
              // check for custom Response message
              if (
                interceptedResponseFlag || (
                  Object.keys(this.state.customResponses).length !== 0 &&
                  this.state.customResponses[res.data.intent]
                )
              ) {
                this.currentIntent = res.data.intent;
                let customMessage = interceptedResponseFlag ? response : this.state.customResponses[res.data.intent] ;
                this.state.customMessages.push(customMessage);
                let newConversationResponse = NEW_CONVERSATION_MESSAGE;
                if (this.state.followUpMessage) {
                  newConversationResponse = this.state.followUpMessage;
                }
                if (originalLanguageCode && originalLanguageCode !== appLanguageCode) {
                  if (customMessage === response) {
                    newConversationResponse = await services.translateText(newConversationResponse, originalLanguageCode);
                  } else {
                    const joinedCustomResponse = joinStrings([customMessage, newConversationResponse])
                    const translatedText = await services.translateText(joinedCustomResponse, originalLanguageCode);
                    [customMessage, newConversationResponse] = splitStrings(translatedText)

                  }
                }
                const respLink = this.urlRegexExpression[Symbol.match](customMessage);
                  this.searchResponse = [];
                  if (respLink && respLink[0]) {
                    let title = customMessage;

                    respLink.forEach((subLink, index) => {
                      const messageIndex = this.state.messages.length;
                      let splitTitle = title.split(subLink);
                      let messageTitle = splitTitle[0];
                      title = splitTitle[1].trim();

                      this.searchResponse = [{title: 'visit us here', link: subLink}];
                      this.searchResponseList[messageIndex] = this.searchResponse;

                      if (index + 1 === respLink.length) {
                        this.finalLink = subLink;
                      }

                      this.state.messages.push({
                        title: messageTitle.trim(),
                        isBot: 1,
                        time: getMessageTime()
                      });
                    });
                    this.link = true;
                    this.haslinks = true;
                    this.setState({...this.state});
                  } 
                  this.resetConfirmationState();
                  if(!(respLink && respLink[0])){
                    this.restartConversation(customMessage);
                  }
                  this.restartConversation(newConversationResponse);
                  return
              }

              // this.showConfirmationMessage = true;
              this.loader = false;
              this.currentIntent = res.data.intent;
              // fallback to locked intent in case of intent forward register name, email etc
              if ((res.data.intent as string).startsWith('FW_register') && res?.data?.lockedIntent) {
                this.currentIntent = res.data.lockedIntent;
              }

              App.disableMessageButton = true;
              const newConversationResponse = originalLanguageCode && originalLanguageCode !== appLanguageCode ? await services.translateText(NEW_CONVERSATION_MESSAGE, originalLanguageCode) : NEW_CONVERSATION_MESSAGE ;
              if (
                this.currentIntent === NOLA_PEST_INTENT ||
                this.currentIntent === HURRICANE_IDA_INTENT
              ) {
                this.resetConfirmationState();
                this.restartConversation(response);
                this.restartConversation(newConversationResponse);
                return;
              }

              if (
                this.state.account_city.toLowerCase() === NEW_ORLEANS &&
                this.currentIntent === ANIMAL_SERVICE_INTENT
              ) {
                this.resetConfirmationState();
                this.restartConversation(response);
                this.restartConversation(newConversationResponse);
                return;
              }

              // create request confirmation messages
              // if (res.data.intent === MESSAGE_INTENT) {
              //     this.state.messages.push({title: CONFIRMATION_MESSAGE_MESSAGE_INTENT, isBot: 1, time: getMessageTime()});
              // } else {
              //     this.state.messages.push({title: CONFIRMATION_MESSAGE, isBot: 1, time: getMessageTime()});
              // }
              this.setState({...this.state}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });

              this.fetchRequestQuestions();
            }
          })
          .catch((err) => {
            if (!this.cancelRequest) this.askIssueAgain();
          });
      })
      .catch((err) => {
        if (!this.cancelRequest) this.askIssueAgain();
      });
  };

  fetchRequestQuestions = () => {
    this.loader = true;
    services
      .getIntentQuestions(this.currentIntent, this.id)
      .then(async (response) => {
        console.log(' this.state.sourceLanguageCode', this.state.sourceLanguageCode)
        this.loader = false;
        const data = (response.data as any).data;
        if (data.questions.length) {
          let translatedQuestionsTitles = data.questions.map((elem: { title: string; }) => elem.title)
          console.log('translatedQuestionsTitles => ',translatedQuestionsTitles)
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            const translatedTitleResponse = await services.translateText(joinStrings(translatedQuestionsTitles), this.state.sourceLanguageCode)
            translatedQuestionsTitles = splitStrings(translatedTitleResponse)
            console.log('translatedQuestionsTitles after translation => ',translatedQuestionsTitles)
          }
          if (
            !(
              this.slots['permitType'] &&
              this.slots['permitType'].toLowerCase() === 'resident'
            )
          ) {
            this.setState({
              originalQuestionsList: data.questions.map((elem: any, index: number) => ({ ...elem, translatedTitle: translatedQuestionsTitles[index] })),
              questionsList: data.questions.map((elem: any,index: number ) => ({ ...elem, translatedTitle: translatedQuestionsTitles[index] })),
              questionCount: data.questions.length,
              questionNo: 1,
            });
          }

          let intentFlowQuestions: any = [];
          if (this.currentIntent === WASTE_SERVICE_INTENT) {
            intentFlowQuestions = getNolaWasteServiceQuestions(
              this.slots['startStop']
            );
          }
          if (this.currentIntent === NOLA_TREE_INTENT) {
            intentFlowQuestions = getNolaTreeIntentQuestions(
              this.slots['treeServiceType']
            );
          }
          if (this.currentIntent === PARKING_PERMIT_INTENT) {
            intentFlowQuestions = getParkingPermitIntentFlowQuestions(
              this.state.account_city.toLowerCase()
            );
          }
          if (this.currentIntent === NOLA_FLOOD_INTENT) {
            intentFlowQuestions = getNolaFloodIntentQuestions(
              this.slots['floodingType']
            );
            if (!this.slots['floodingType']) {
              this.slots['floodingType'] = 'flooding';
            }
          }
          if (
            this.state.account_city.toLowerCase() === NEW_ORLEANS &&
            this.currentIntent === PUBLIC_PROPERTY_INTENT &&
            !this.slots['damageType'] &&
            !this.slots['propertyType']
          ) {
            intentFlowQuestions = getNolaPropertyAndDamageTypeNullQuestions();
          }

          if (intentFlowQuestions.length) {
            this.setState(
              {
                ...this.state,
                questionsList: [
                  ...this.state.questionsList,
                  ...intentFlowQuestions,
                ],
                questionCount:
                  this.state.questionCount + intentFlowQuestions.length,
              },
              () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              }
            );
          }

          const firstQuestion = this.state.questionsList[0];
          const title: string = firstQuestion['title'];
          let translatedTitle: string = firstQuestion['translatedTitle'];
          if (!translatedTitle || title === translatedTitle) {
            if (this.state.sourceLanguageCode !== appLanguageCode) {
              translatedTitle = await services.translateText(title, this.state.sourceLanguageCode)
            } else {
              translatedTitle = title
            }
          }
          this.state.messages.push({
            title: translatedTitle,
            isBot: 1,
            time: getMessageTime(),
          });
          this.state.intentQuestionsList.push(this.state.questionsList[0]);
          this.setState({questionNo: 1}, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });
          this.intentId = data.intent.id;
        } else {
          console.log(this);
          let unableToRegisterProblemResponse = '';
          if (this.state.botRephraseResponse) {
            const { message } = rephraseDyanmicMessage(this.state.botRephraseResponse);
            unableToRegisterProblemResponse = message; 
          }
          else {
            unableToRegisterProblemResponse = CANNOT_REGISTER_PROBLEM;
          }
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            unableToRegisterProblemResponse = await services.translateText(unableToRegisterProblemResponse, this.state.sourceLanguageCode)
          }
          this.thanksResponse(unableToRegisterProblemResponse);
        }
      })
      .catch((err) => {
        this.resetConfirmationState();
        if (!this.cancelRequest) this.askIssueAgain();
      });
  };

  setupSurvey = async () => {
    if (
      this.currentSurveyQuestion < this.totalSurveyQuestions &&
      this.state.surveyList[this.currentSurveyQuestion]
    ) {
      let title = this.state.surveyList?.[this.currentSurveyQuestion]?.['title'] ? this.state.surveyList?.[this.currentSurveyQuestion]?.['title'] as string : ''
      if (this.state.sourceLanguageCode !== appLanguageCode) {
        title = await services.translateText(title, this.state.sourceLanguageCode)
      }
      this.state.messages.push({
        title,
        isBot: 1,
        time: getMessageTime(),
      });
    }
    this.currentSurveyQuestion++;

    this.loader = false;
    this.setState({...this.state}, () => {
      this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
    });
  };

  // Handles the survey questions
  _handleSurvey = async(message: string) => {
    App.disableMessageButton = true;
    if (!this.startSurvey) {
      const replyMessage = message.toLowerCase();
      if (
        replyMessage.includes(NO_THANK_YOU) ||
        replyMessage.includes(NO_THANKS) ||
        replyMessage.includes(NO_THANKYOU) ||
        replyMessage.includes(NEVERMIND) ||
        replyMessage.includes(NEVER_MIND) ||
        replyMessage.includes(NO) ||
        replyMessage.includes(NOTHANKYOU) ||
        replyMessage.includes(NOTHANKYOU) ||
        replyMessage.includes(THANKS) ||
        replyMessage.includes(THANK_YOU) ||
        replyMessage.includes(THANKYOU)
      ) {
        this.endConversation = true;

        let endConversationResponse = END_CONVERSATION_MESSAGE
        if (this.state.sourceLanguageCode !==  appLanguageCode) {
          endConversationResponse = await services.translateText(endConversationResponse, this.state.sourceLanguageCode)
        }
        this.state.messages.push({
          title: endConversationResponse,
          isBot: 1,
          time: getMessageTime(),
        });
        this.loader = false;
        this.setState({...this.state}, () => {
          this.chat_space.current.scrollTop =
            this.chat_space.current.scrollHeight;
        });
      } else {
        this.setState({
          surveyList: surveyMessages,
        });

        this.totalSurveyQuestions = surveyMessages.length;
        this.startSurvey = true;
        this.setupSurvey();
      }
    } else {
      this.setupSurvey();
    }
  };

  // Submits the survery response of the user
  submitSurveyResponse = () => {
  };

  submitRequest = async (message: string) => {
    this.loader = true;
    console.log('submitRequest message =>',message )
    console.log('submitRequest this.createOtherTypeRequest =>',this.createOtherTypeRequest )
    this.state.messages.push({
      title: message,
      isBot: 0,
      time: getMessageTime(),
    });
    this.setState({...this.state, submittingRequest: true}, () => {
      this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
    });
    const dialogHistoryJoinStr = (this.dialogHistory.map(obj=>([obj.citibot, obj.userInput].join(' ||| ')))).join(' |||| ')
    const enDialogHistoryStr = await services.translateText(dialogHistoryJoinStr, appLanguageCode);
    const enDialogHistory = enDialogHistoryStr.split(' |||| ').map(singleConversationStr=>singleConversationStr.split(' ||| ')).map(singleConversationArr=>({'citibot':singleConversationArr[0], 'userInput':singleConversationArr[1]}))
    const nameRegex = /\bname\b/;
    const emailRegex = /\bemail\b/;
    const nameAnswer= enDialogHistory.find(singleConversation=>(nameRegex.test(singleConversation.citibot.toLowerCase())));
    if(nameAnswer){
      nameAnswer.userInput = this.slots.name
    }
    const emailAnswers= enDialogHistory.filter(singleConversation=>(emailRegex.test(singleConversation.citibot.toLowerCase())));
    if(emailAnswers.length > 1){
      const finalEmailAnswer = emailAnswers.find(emailAnswer=> emailAnswer.citibot.toLowerCase().includes('valid'));
      if(finalEmailAnswer){
        finalEmailAnswer.userInput = this.slots.email
      }
    }else if(emailAnswers.length){
      emailAnswers[0].userInput = this.slots.email
    }
    services
      .postIssue(
        this.id,
        this.intentId,
        this.currentIntent,
        this.contactNo,
        enDialogHistory,
        this.slots,
        this.createOtherTypeRequest
      )
      .then(async (res: AxiosResponse) => {
        let requestSubmittedMessage = '';
        let roseVilleConfirmResponse: { title: string, link: string }[] = [];
        let coloradoSpringsConfirmResponse: { title: string, link: string }[] = [];
        //city specific to baltimore
        if (this.state.account_city.toLowerCase() === BALTIMORE &&
          res.data?.message === BALT_ADDRESS_VALIDATION_ERROR) {
          requestSubmittedMessage = CITIBOT_ADDRESS_ERROR_MSG;
        } else if (this.state.account_city.toLowerCase() === ROSEVILLE &&
          res.data?.message === ROSEVILLE_ADDRESS_VALIDATION_ERROR) {
          requestSubmittedMessage = CITIBOT_ADDRESS_ERROR_MSG;
        } else if ([DENVER, FLORENCE].includes(this.state.account_city.toLowerCase()) &&
          res.data?.message === ADDRESS_VALIDATION_ERROR) {
          requestSubmittedMessage = CITIBOT_ADDRESS_ERROR_MSG;
        } else if (this.state.crmFailMessage && res.data?.message === "failed") {
          requestSubmittedMessage = this.state.crmFailMessage
        }
        else if (this.state.crmSuccessMessage && res.data?.message.toLowerCase() === CITIBOT_SUCCESS_MSG) {
          requestSubmittedMessage = SuccessDynamicMessage(res.data?.requestId, this.state.crmSuccessMessage);
        }
        else if (
          this.state.account_city.toLowerCase() === ROSEVILLE &&
          res.data?.message === CITIBOT_SUCCESS_MSG
        ) {
          const { message, searchResponse } = rosevilleDynamicMessage(res.data.requestId, this.currentIntent, this.state.confirmationMessageResponse);
          requestSubmittedMessage = message;
          roseVilleConfirmResponse = searchResponse;
        } else if (
          this.state.account_city.toLowerCase() === ColoradoSprings &&
          res.data?.message.toLowerCase() === CITIBOT_SUCCESS_MSG
        ) {
          const {message, searchResponse} = CSSucessDyanmicMessage(res.data?.requestId);
          requestSubmittedMessage = message;
          coloradoSpringsConfirmResponse = searchResponse;
        }
        else {
          requestSubmittedMessage = REQUEST_SUBMITTED_MESSAGE;
        }
        if (
          this.currentIntent === WASTE_CONTAIER_INTENT &&
          this.slots['wasteContainerAction'] &&
          this.slots['wasteContainerAction'].toLowerCase().includes('replace')
        ) {
          requestSubmittedMessage = WASTE_CONTAINER_REPORT_SUBMITTED;
        } else if (this.currentIntent === WASTE_CONTAIER_INTENT) {
          requestSubmittedMessage = WASTE_CONTAINER_REQUEST_SUBMITTED;
        } else if (this.state.account_city.toLowerCase() === OAKLAND) {
          requestSubmittedMessage = OAKLAND_REQUEST_SUBMITTED_MESSAGE;
        }        
        let newConversationResponse = START_NEW_CONVERSATION_MESSAGE
        if (this.state.followUpMessage) {
          newConversationResponse = this.state.followUpMessage;
        }

        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([requestSubmittedMessage, newConversationResponse]), this.state.sourceLanguageCode);
          [requestSubmittedMessage, newConversationResponse] = splitStrings(translatedResponse)
        }
        this.state.messages.push({
          title: requestSubmittedMessage,
          isBot: 1,
          time: getMessageTime(),
        });
        if (roseVilleConfirmResponse.length > 0) {
          this.haslinks = true;
          this.link = true;
          this.searchResponseList[this.state.messages.length - 1] = roseVilleConfirmResponse;
          this.setState({ ...this.state }, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });
        }
        if (coloradoSpringsConfirmResponse.length > 0) {
          this.haslinks = true;
          this.link = true;
          this.searchResponseList[this.state.messages.length - 1] = coloradoSpringsConfirmResponse;
          this.setState({ ...this.state }, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });
        }
        this.state.messages.push({
          title: newConversationResponse,
          isBot: 1,
          time: getMessageTime(),
        });
        this.continueConvQues = true;
        App.disableMessageButton = false;
        this.loader = false;
        this.resetConfirmationState();
        this.isConfirmationRejected = false;
        this.requestConfirmed = false;
        this.setState({...this.state, submittingRequest: false});
        this.setState({...this.state}, () => {
          this.chat_space.current.scrollTop =
            this.chat_space.current.scrollHeight;
        });
      })
      .catch(async (err) => {
        this.resetConfirmationState();
        if (!this.cancelRequest) {
          let requestNotSubmittedResponse = REQUEST_NOT_SUBMITTED_MESSAGE
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            requestNotSubmittedResponse = await services.translateText(requestNotSubmittedResponse, this.state.sourceLanguageCode)
          }
          this.thanksResponse(requestNotSubmittedResponse);
        }
      });
  };

  addNextQuestion = async (message: string) => {
    const newIntentSlotQuestions = this.state.intentQuestionsList.slice()
    this.slots[this.state.questionsList[this.state.questionNo - 1]['slot']] =
      message;
    let nextQuestion = this.state.questionsList[this.state.questionNo];
    if (
      ([BALTIMORE, ROSEVILLE].includes(this.state.account_city.toLowerCase())) &&
      message.toLowerCase() !== 'other' && 
      !nextQuestion?.['required'] &&
        this.currentIntent !== BALTIMORE_ILLEGAL_DUMPING_INTENT
    ) {
      console.log('before questionNo =>', this.state.questionNo)
      newIntentSlotQuestions.push(
        this.state.questionsList[this.state.questionNo]
      )
      console.log('incrementedQuestionNumber =>', this.state.questionNo + 1)
      console.log('this.state.questionCount =>',this.state.questionCount)
      const incrementedQuestionNumber = this.state.questionNo + 1;
      if (incrementedQuestionNumber < this.state.questionCount) {
        this.setState({
          ...this.state,
          questionNo: this.state.questionNo++,
          intentQuestionsList: newIntentSlotQuestions
        })
      } else {
        console.log('last question encountered =>',incrementedQuestionNumber, this.state.questionNo , message)
        this.validatePhoneNumber = 0;
        this.validateEmailAddress = 0;
        this.validateRoseVilleDate = 0;
        this.validateAddress = 0;
        this.validateQuestion = 0;
        this.handleSubmitConfirmation(message);
        return;
      }

      console.log('after questionNo =>', incrementedQuestionNumber)
    }
    nextQuestion = this.state.questionsList[this.state.questionNo];
    let title: string = nextQuestion['title'];
    let translatedTitle: string = nextQuestion['translatedTitle'];
    if (!translatedTitle || title === translatedTitle) {
      if (this.state.sourceLanguageCode !== appLanguageCode) {
        translatedTitle = await services.translateText(title, this.state.sourceLanguageCode)
      } else {
        translatedTitle = title
      }
    }
    this.state.messages.push({
      title: translatedTitle,
      isBot: 1,
      time: getMessageTime(),
    });
    if (this.slots?.isPrivateProperty === 'no') {
        if (this.state.content?.slotSpecificCustomResponsesYardUpkeepPublicProperty &&
        this.state.content?.slotSpecificCustomResponsesYardUpkeepPublicProperty[this.currentIntent]) {
      let customMessage = this.state.content?.slotSpecificCustomResponsesYardUpkeepPublicProperty[this.currentIntent] ;
      let newConversationResponse = NEW_CONVERSATION_MESSAGE ; 
      let originalLanguageCode = this.state.sourceLanguageCode;
      let response = this.state.responseMessage;
      if (originalLanguageCode !== appLanguageCode) {
        if (customMessage === response) {
          newConversationResponse = await services.translateText(newConversationResponse, originalLanguageCode);
        } else {
          const joinedCustomResponse = joinStrings([customMessage, newConversationResponse])
          const translatedText = await services.translateText(joinedCustomResponse, originalLanguageCode);
          [customMessage, newConversationResponse] = splitStrings(translatedText)

        }
      }
      const respLink = this.urlRegexExpression[Symbol.match](customMessage);
        this.searchResponse = [];
        if (respLink && respLink[0]) {
          let title = customMessage;

          respLink.forEach((subLink, index) => {
            const messageIndex = this.state.messages.length;
            let splitTitle = title.split(subLink);
            let messageTitle = splitTitle[0];
            title = splitTitle[1].trim();

            this.searchResponse = [{title: 'visit us here', link: subLink}];
            this.searchResponseList[messageIndex] = this.searchResponse;

            if (index + 1 === respLink.length) {
              this.finalLink = subLink;
            }

            this.state.messages.push({
              title: messageTitle.trim(),
              isBot: 1,
              time: getMessageTime()
            });
          });
          this.link = true;
          this.haslinks = true;
          this.setState({...this.state});
        } 
        this.resetConfirmationState();
        if(!(respLink && respLink[0])){
          this.restartConversation(customMessage);
        }
        this.restartConversation(newConversationResponse);
         return
        }
    }
    else if (this.slots?.isPrivateProperty === 'yes') {
      if (this.state.content?.slotSpecificCustomResponsesYardUpkeepPrivateProperty &&
      this.state.content?.slotSpecificCustomResponsesYardUpkeepPrivateProperty[this.currentIntent]){
      let customMessage = this.state.content?.slotSpecificCustomResponsesYardUpkeepPrivateProperty[this.currentIntent] ;
      let newConversationResponse = NEW_CONVERSATION_MESSAGE ; 
      let originalLanguageCode = this.state.sourceLanguageCode;
      let response = this.state.responseMessage;
      if (originalLanguageCode !== appLanguageCode) {
        if (customMessage === response) {
          newConversationResponse = await services.translateText(newConversationResponse, originalLanguageCode);
        } else {
          const joinedCustomResponse = joinStrings([customMessage, newConversationResponse])
          const translatedText = await services.translateText(joinedCustomResponse, originalLanguageCode);
          [customMessage, newConversationResponse] = splitStrings(translatedText)

        }
      }
      const respLink = this.urlRegexExpression[Symbol.match](customMessage);
        this.searchResponse = [];
        if (respLink && respLink[0]) {
          let title = customMessage;

          respLink.forEach((subLink, index) => {
            const messageIndex = this.state.messages.length;
            let splitTitle = title.split(subLink);
            let messageTitle = splitTitle[0];
            title = splitTitle[1].trim();

            this.searchResponse = [{title: 'visit us here', link: subLink}];
            this.searchResponseList[messageIndex] = this.searchResponse;

            if (index + 1 === respLink.length) {
              this.finalLink = subLink;
            }

            this.state.messages.push({
              title: messageTitle.trim(),
              isBot: 1,
              time: getMessageTime()
            });
          });
          this.link = true;
          this.haslinks = true;
          this.setState({...this.state});
        } 
        this.resetConfirmationState();
        if(!(respLink && respLink[0])){
          this.restartConversation(customMessage);
        }
        this.restartConversation(newConversationResponse);
         return
      }
    }
    newIntentSlotQuestions.push(
      this.state.questionsList[this.state.questionNo]
    );
    console.log('add question this.state.intentQuestionsList => ', newIntentSlotQuestions)
    this.setState({questionNo: this.state.questionNo + 1, intentQuestionsList: newIntentSlotQuestions}, () => {
      this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
    });
  };

  /** We'll terminate the message if baltimore illegal intents 
   * contains a no to non-required questions
  */
  handleTerminatingMessageFunc(message: string) {
    this.validatePhoneNumber = 0;
    this.validateEmailAddress = 0;
    this.validateAddress = 0;
    this.validateQuestion = 0;
    this.handleSubmitConfirmation(message);
  };

  //BALT illegal intent
  async askRequiredQuestion(message: string) {
    this.slots[this.state.questionsList[this.state.questionNo - 1]['slot']] =
          message;
    const newIntentSlotQuestions = this.state.intentQuestionsList.slice()
    let nextQuestion = this.state.questionsList[this.state.questionNo + 1];

    let nextQuestionIndex = this.state.questionNo + 1;

    // Skip non-required questions
    while (nextQuestion && !nextQuestion['required']) {
      nextQuestionIndex++;
      nextQuestion = this.state.questionsList[nextQuestionIndex];
    }

    if (typeof nextQuestion != 'undefined') {
      this.setState({
        ...this.state,
        questionNo: nextQuestionIndex,
        intentQuestionsList: [...newIntentSlotQuestions, nextQuestion]
      }, async () => {
        let title: string = nextQuestion['title'];
        let translatedTitle: string = nextQuestion['translatedTitle'];
        if (!translatedTitle || title === translatedTitle) {
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            translatedTitle = await services.translateText(title, this.state.sourceLanguageCode)
          } else {
            translatedTitle = title
          }
        }
        this.state.messages.push({
          title: translatedTitle,
          isBot: 1,
          time: getMessageTime(),
        });
        newIntentSlotQuestions.push(
          this.state.questionsList[nextQuestionIndex]
        );
        console.log('add question this.state.intentQuestionsList => ', newIntentSlotQuestions)
        this.setState({ questionNo: nextQuestionIndex + 1, intentQuestionsList: newIntentSlotQuestions }, () => {
          this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
        });
      });
    }
  }

  // BALT grass mowing intent
  async askNonRequiredQuestion(message: string) {
    this.slots[this.state.questionsList[this.state.questionNo - 1]['slot']] =
      message;
    const newIntentSlotQuestions = this.state.intentQuestionsList.slice()
    let nextQuestion = this.state.questionsList[this.state.questionNo];

    let nextQuestionIndex = this.state.questionNo;

    // Skip required questions
    while (nextQuestion && nextQuestion['required']) {
      nextQuestionIndex++;
      nextQuestion = this.state.questionsList[nextQuestionIndex];
    }

    if (typeof nextQuestion != 'undefined') {
      this.setState({
        ...this.state,
        questionNo: nextQuestionIndex,
        intentQuestionsList: [...newIntentSlotQuestions, nextQuestion]
      }, async () => {
        let title: string = nextQuestion['title'];
        let translatedTitle: string = nextQuestion['translatedTitle'];
        if (!translatedTitle || title === translatedTitle) {
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            translatedTitle = await services.translateText(title, this.state.sourceLanguageCode)
          } else {
            translatedTitle = title
          }
        }
        this.state.messages.push({
          title: translatedTitle,
          isBot: 1,
          time: getMessageTime(),
        });
        newIntentSlotQuestions.push(
          this.state.questionsList[nextQuestionIndex]
        );
        console.log('add question this.state.intentQuestionsList => ', newIntentSlotQuestions)
        this.setState({ questionNo: nextQuestionIndex + 1, intentQuestionsList: newIntentSlotQuestions }, () => {
          this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
        });
      });
    }
  }

  ValidateEmail = (mail: string) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  storeAddress = (reply: string) => {
    const messagesListLength = this.state.messages.length;
    const lastQuestion = this.state.messages[messagesListLength - 1];
    if (lastQuestion.title.toLowerCase().includes('what is the address')) {
      this.issueAddress = reply;
    }
  };

  _handleContinueConversationQuestion = async (message: string) => {
    this.loader = true;
    const sentMessage = message
    if (this.state.sourceLanguageCode !== appLanguageCode && message !== 'message') {
      console.log('_handleContinueConversationQuestion translating back to ', appLanguageCode, ' => ', sentMessage)
      message = await services.translateText(message, appLanguageCode)
    }
    const replyMessage = message.toLowerCase();

    if (
      isNoUtteranceMessage(replyMessage)
    ) {
      //Disabled survey for now.
      // App.disableMessageButton = true;
      // this.loader = false;
      // this.lastQues = true;
      // this.takeSurvey = true;
      // this.state.messages.push({
      //     title: TAKE_SURVEY_MESSAGE,
      //     isBot: 1,
      //     time: getMessageTime()
      // });

      // this.loader = false;
      // this.setState({...this.state, surveyList: surveyMessages}, () => {
      //     this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
      // });

      //Survey is replaced with END_CONVERSATION here.
      this.endConversation = true;


      this.state.messages.push({
        title: sentMessage,
        isBot: 0,
        time: getMessageTime(),
      });

      let endConversationResponse = END_CONVERSATION_THANKS_MESSAGE
      if (this.state.sourceLanguageCode !== appLanguageCode) {
        console.log('translating back to ', this.state.sourceLanguageCode, ' => ', sentMessage)
        endConversationResponse = await services.translateText(endConversationResponse, this.state.sourceLanguageCode)
      }

      this.state.messages.push({
        title: endConversationResponse,
        isBot: 1,
        time: getMessageTime(),
      });
      this.loader = false;
      this.setState({...this.state}, () => {
        this.chat_space.current.scrollTop =
          this.chat_space.current.scrollHeight;
      });
    } else {
      this.continueConvQues = false;
      this.dialogHistory = [];
      this.setState({...this.state, questionNo: -1});
      this.loader = false;

      if (
        replyMessage === YES ||
        replyMessage === YA_SURE ||
        replyMessage === SURE ||
        replyMessage === YES_SURE ||
        replyMessage === DEFINITELY
      ) {
        let continueConversationResponse = '';
        if (this.state.followUpMessage) {
          continueConversationResponse = this.state.followUpMessage;
        } else {
          continueConversationResponse = CONTINUE_CONVERSATION_MESSAGE;
        }
        let newIssueAskResponse = ''
        if (this.state.account_city.toLowerCase() === ColoradoSprings) {
          newIssueAskResponse = COLORADO_ASK_NEW_MESSAGE;
        } else if (this.state.removeMessageButtonPhrase && this.state.removeMessageButton) {
          newIssueAskResponse = this.state.removeMessageButtonPhrase;
        } else {
          newIssueAskResponse = ASK_NEW_ISSUE_MESSAGE;
        }

        if (this.state.sourceLanguageCode !== appLanguageCode) {
          [continueConversationResponse, newIssueAskResponse] = await Promise.all([
            services.translateText(continueConversationResponse, this.state.sourceLanguageCode),
            services.translateText(newIssueAskResponse, this.state.sourceLanguageCode)
          ]);
        }
        this.dialogHistory.push({
          userInput: message,
          citibot: continueConversationResponse,
        });
        App.disableMessageButton = false;

        this.state.messages.push({
          title: sentMessage,
          isBot: 0,
          time: getMessageTime(),
        });
        this.state.messages.push({
          title: newIssueAskResponse,
          isBot: 1,
          time: getMessageTime(),
        });
        this.setState({...this.state}, () => {
          this.chat_space.current.scrollTop =
            this.chat_space.current.scrollHeight;
        });
      } else {
        this._addMessage(sentMessage !== message ? sentMessage : message);
      }
    }
  };

  skipEmailValidation(message: string) {
    return (
      message.toLowerCase() === NO_THANKS ||
      message.toLowerCase() === NOTHANKS ||
      message.toLowerCase() === NO_THANK_YOU ||
      message.toLowerCase() === NO_THANKYOU ||
      message.toLowerCase() === NOTHANKYOU ||
      message.toLowerCase() === NO
    );
  }

  /**This will trigger the new intent for baltimore bot based on
   * selected ans for previous intents
   */
  async triggerTheNewIntent(reply: string, newContactNo?: string) {
    const elements = document.getElementsByClassName("multivalued-fields_choiceFields__31j7m");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      // This will remove the dropdown list after selecting a value from previous intent
      element.style.display = "none";
    }
    const contactNo = newContactNo ? newContactNo : this.contactNo;
    this.loader = true;
    services.getIntent(contactNo, this.phone_number, CANCEL)
      .then(res => {
        services.getIntent(contactNo, this.phone_number, reply)
          .then(async (res) => {
            this.currentIntent = res?.data.intent;
            const responseData = res?.data || {};
            const { originalLanguageCode, response } = responseData;

            this.setState({
              showMessageButton: false,
              sourceLanguageCode: originalLanguageCode || appLanguageCode,
              responseMessage: response
            });
            services.getIntentQuestions(this.currentIntent, this.id)
              .then(async (response) => {
                this.loader = false;
                const data = (response.data as any).data;
                if (data.questions.length) {
                  // Eliminate the first four questions
                  const remainingQuestions = data.questions.slice(4);
                  let translatedQuestionsTitles = remainingQuestions.map((elem: { title: string }) => elem.title);
                  console.log('translatedQuestionsTitles => ', translatedQuestionsTitles);
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    const translatedTitleResponse = await services.translateText(joinStrings(translatedQuestionsTitles), this.state.sourceLanguageCode);
                    translatedQuestionsTitles = splitStrings(translatedTitleResponse);
                    console.log('translatedQuestionsTitles after translation => ', translatedQuestionsTitles);
                  }
                  this.setState({
                    questionsList: remainingQuestions.map((elem: any, index: number) => ({ ...elem, translatedTitle: translatedQuestionsTitles[index] })),
                    questionCount: remainingQuestions.length,
                    intentQuestionsList: []
                  });
                  const firstQuestion = this.state.questionsList[0];
                  const title: string = firstQuestion['title'];
                  let translatedTitle: string = firstQuestion['translatedTitle'];
                  if (!translatedTitle || title === translatedTitle) {
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      translatedTitle = await services.translateText(title, this.state.sourceLanguageCode);
                    } else {
                      translatedTitle = title;
                    }
                  }
                  this.state.messages.push({
                    title: translatedTitle,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.state.intentQuestionsList.push(this.state.questionsList[0]);
                  this.setState({ questionNo: 1 }, () => {
                    this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
                  });
                  this.intentId = data.intent.id;
                }
              });
          });
      });
  }

  _addMessage = async(message: string) => {
    console.log('_addMessage called with message =>', message)
    getMessageTime();
    const sentMessage = message
    const currentSlot = this.state.questionsList[this.state.questionNo - 1] ? this.state.questionsList[this.state.questionNo - 1]['slot'] : "";

    if(currentSlot as string === 'name'){
      const name = await services.translateText(message, appLanguageCode)
      if(["anon", "anonymous", "n/a", "none", "no", "na"].includes(name.toLowerCase())){
        message = name;
      }
    } else if ((this.state.sourceLanguageCode !== appLanguageCode && message !== 'message' && !['email'].includes(currentSlot))) {
      console.log('_addMessage translating back to this.state.sourceLanguageCode ',this.state.sourceLanguageCode, ' appLanguageCode =>', appLanguageCode, ' => ', sentMessage)
      message = await services.translateText(message, appLanguageCode)
    }

    if (!this.contactNo) {
      this.contactNo = this.generateRandomUser(11);
    }

    if (
      message.trim().length === 0 &&
      this.state.questionNo >= 0 &&
      this.state.questionNo < this.state.questionCount &&
      !this.startSurvey &&
      this.state.account_city.toLowerCase() === NEW_ORLEANS &&
      this.state.custom_account_intents &&
      this.currentIntent in this.state.custom_account_intents &&
      this.state.custom_account_intents[this.currentIntent].slots &&
      this.state.questionsList[this.state.questionNo - 1]['slot'] in
      this.state.custom_account_intents[this.currentIntent].slots &&
      this.validateQuestion <= 3
    ) {
      // const question: string = this.state.questionsList[this.state.intentQuestionsList.length - 1]["title"];
      this.validateQuestion++;
      if (this.validateQuestion === 4) {
        
        let requiredAnswerResponse = ANSWER_IS_REQUIRED
        let newConversationResponse = NEW_CONVERSATION_MESSAGE

        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([requiredAnswerResponse, newConversationResponse]), this.state.sourceLanguageCode);
          [requiredAnswerResponse, newConversationResponse] = splitStrings(translatedResponse)
        }

        this.resetConfirmationState();
        this.restartConversation(requiredAnswerResponse);
        this.restartConversation(newConversationResponse);
        return;
      }
    }

    if (message.trim().length !== 0) {
      const isSpecialMessage = this.recognizeSpecialMessages(
        message.toLowerCase()
      );

      // submit a request if user responds with a yes utterance to
      // submit confirmation message else start a new conversation
      if (!isSpecialMessage && this.showConfirmSubmissionMessage) {
        this.loader = true;
        if (isYesUtterance(message.toLowerCase())) {
          this.submitRequest(sentMessage);
        } else {
          this.resetConfirmationState();
          this.state.messages.push({
            title: sentMessage,
            isBot: 0,
            time: getMessageTime(),
          });
          this.setState({...this.state, submittingRequest: true}, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });
          let newConversationMessage = START_NEW_CONVERSATION_MESSAGE;
          if (this.state.SRRejectionMessage) {
            newConversationMessage = this.state.SRRejectionMessage;
          }
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            newConversationMessage = await services.translateText(newConversationMessage, this.state.sourceLanguageCode)
          }
          services
            .getIntent(this.contactNo, this.phone_number, CANCEL)
            .then((res) => {
              this.restartConversation(newConversationMessage);
            })
            .catch((err) => {
              this.restartConversation(newConversationMessage);
            });
        }
        return;
      }

      const messagesListLength = this.state.messages.length;
      const lastQuestion = messagesListLength
        ? this.state.messages[messagesListLength - 1]
        : undefined;
      if (
        lastQuestion        
      ) {
        let lastQuestionTitle = lastQuestion['title']
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          lastQuestionTitle = await services.translateText(lastQuestionTitle, appLanguageCode)
        }
        const messageTypes = [
          NEW_CONVERSATION_MESSAGE,
          SEARCH_RESULT_READY_MESSAGE,
          VISIT_LINK_MESSAGE,
          this.state.followUpMessage,
        ];
        if (
          messageTypes.includes(lastQuestionTitle)
        ) {
          console.log('last question block =>', lastQuestion)
          console.log('last question title =>', lastQuestionTitle)
          if (isYesUtterance(message)) {
            this.state.messages.push({
              title: sentMessage,
              isBot: 0,
              time: getMessageTime(),
            });
            let issueMessage = '';
            if (this.state.account_city.toLowerCase() === ColoradoSprings) {
              issueMessage = COLORADO_ASK_NEW_MESSAGE;
            } else if (this.state.account_city.toLowerCase() === WEST_HOLLYWOOD) {
              issueMessage = ISSUE_HELP_MESSAGE_COMPACT;
            } else if (this.state.removeMessageButtonPhrase && this.state.removeMessageButton) {
              issueMessage = this.state.removeMessageButtonPhrase;
            }
            else {
              issueMessage = ASK_NEW_ISSUE_MESSAGE;
            }
            if (this.state.sourceLanguageCode !== appLanguageCode) {
              issueMessage = await services.translateText(issueMessage, appLanguageCode);
            }
            this.state.messages.push({
              title: issueMessage,
              isBot: 1,
              time: getMessageTime(),
            });
            this.setState({...this.state}, () => {
              this.chat_space.current.scrollTop =
                this.chat_space.current.scrollHeight;
            });
            App.disableMessageButton = false;
            return;
          } else if (isNoUtteranceMessage(message)) {
            let endConversationThanksMessage = END_CONVERSATION_THANKS_MESSAGE

            if (this.state.sourceLanguageCode !== appLanguageCode) {
              endConversationThanksMessage = await services.translateText(endConversationThanksMessage, this.state.sourceLanguageCode);
            }
            this.state.messages.push({
              title: sentMessage,
              isBot: 0,
              time: getMessageTime(),
            });
            if(this.state.endMessage){
              const endMessage= await services.translateText(this.state.endMessage, this.state.sourceLanguageCode);
              this.state.messages.push({
               title: endMessage,
               isBot: 1,
               time: getMessageTime(),
             });
             }
            this.state.messages.push({
              title: endConversationThanksMessage,
              isBot: 1,
              time: getMessageTime(),
            });
            this.loader = false;
            this.setState({...this.state}, () => {
              this.chat_space.current.scrollTop =
                this.chat_space.current.scrollHeight;
            });
            return;
          }
        }
      }

      if (this.currentIntent === EMERGENCY_INTENT) {
        let emergencyContactResponse = EMERGENCY_CONTACT_911
        let newConversationMessage = NEW_CONVERSATION_MESSAGE

        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([emergencyContactResponse, newConversationMessage]), this.state.sourceLanguageCode);
          [emergencyContactResponse, newConversationMessage] = splitStrings(translatedResponse)
        }
        this.resetConfirmationState();
        this.restartConversation(emergencyContactResponse, sentMessage);
        this.restartConversation(newConversationMessage);
        return;
      }
      console.log("this.state.questionsList =>", this.state.questionsList)
      console.log('this.state.intentQuestionsList.length - 1 =>',this.state.intentQuestionsList.length - 1)
      let questionTitle = this.state.questionsList?.[this.state.intentQuestionsList.length - 1]?.[
        'title'
        ] ? this.state.questionsList?.[this.state.intentQuestionsList.length - 1]?.[
          'title'
          ]as string : '' ;
      let questionSlot = this.state.questionsList?.[this.state.intentQuestionsList.length - 1]?.[
        'slot'
        ] ? this.state.questionsList?.[this.state.intentQuestionsList.length - 1]?.[
          'slot'
          ]as string : '';
      // Restart conversation if user has more than 4 units of waste container
      // for cb_waste_container_intent in NOLA.
      if (
        questionTitle &&
        (this.currentIntent === WASTE_CONTAIER_INTENT ||
          this.currentIntent === WASTE_SERVICE_INTENT || 
          this.currentIntent === WASTE_PICKUP_INTENT ) &&
        this.state.intentQuestionsList.length &&
        isYesUtterance(message.toLowerCase())
      ) {
        let wasteContainerOrder = NOLA_WASTE_CONTAINER_ORDER_RESPONSE
        let newConversationMessage = NEW_CONVERSATION_MESSAGE
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([wasteContainerOrder, newConversationMessage]), this.state.sourceLanguageCode);
          [wasteContainerOrder, newConversationMessage] = splitStrings(translatedResponse)
        }
        if (questionTitle === MORE_THAN_FOUR_UNITS || questionTitle === MORE_THAN_FOUR_UNITS_IN_PROPERTY) {
          this.resetConfirmationState();
          this.restartConversation(wasteContainerOrder, sentMessage);
          this.restartConversation(newConversationMessage);
          return;

        }
      }

      // Restart conversation if user has more than 4 units of waste container
      // for cb_waste_container_intent in NOLA.
      // if (
      //   (this.currentIntent === WASTE_CONTAIER_INTENT ||
      //     this.currentIntent === WASTE_SERVICE_INTENT) &&
      //   this.state.intentQuestionsList.length &&
      //   this.state.questionsList[this.state.intentQuestionsList.length - 1][
      //     'title'
      //     ] === MORE_THAN_FOUR_UNITS &&
      //   isYesUtterance(message.toLowerCase())
      // ) {
      //   this.resetConfirmationState();
      //   this.restartConversation(NOLA_WASTE_CONTAINER_ORDER_RESPONSE, message);
      //   this.restartConversation(NEW_CONVERSATION_MESSAGE);
      //   return;
      // }

      // Restart conversation if user has more than 4 units of waste container
      // for cb_waste_pickup_intent in NOLA.
      // if (
      //   this.currentIntent === WASTE_PICKUP_INTENT &&
      //   this.state.intentQuestionsList.length &&
      //   this.state.questionsList[this.state.intentQuestionsList.length - 1][
      //     'title'
      //     ] === MORE_THAN_FOUR_UNITS_IN_PROPERTY &&
      //   isYesUtterance(message.toLowerCase())
      // ) {
      //   this.resetConfirmationState();
      //   this.restartConversation(NOLA_WASTE_CONTAINER_ORDER_RESPONSE, message);
      //   this.restartConversation(NEW_CONVERSATION_MESSAGE);
      //   return;
      // }

      // Restart conversation for nola_tree_intent based on user's reply for some questions.
      if (
        questionTitle &&
        this.currentIntent === NOLA_TREE_INTENT &&
        this.state.intentQuestionsList.length &&
        isYesUtterance(message.toLowerCase())
      ) {
        let contact311Message = CONTACT_311_MESSAGE
        let newConversationMessage = NEW_CONVERSATION_MESSAGE
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([contact311Message, newConversationMessage]), this.state.sourceLanguageCode);
          [contact311Message, newConversationMessage] = splitStrings(translatedResponse)
        }
        if (questionTitle === TREE_ON_POWER_LINE || questionTitle === TREE_BLOCKING_FOLLOWING) {
          this.resetConfirmationState();
          this.restartConversation(contact311Message, sentMessage);
          this.restartConversation(newConversationMessage);
          return;
        }
      }

      // Yard Upkeep Intent Flow For Oakland When The Issue Is On Private Property.
      if (
        questionSlot &&
        this.currentIntent === OAKLAND_YARD_UPKEEP_INTENT &&
        this.state.intentQuestionsList.length
      ) {
        let {title, links} = oaklandYardUpkeepLinks(
          questionSlot,
          message
        );
        if (title) {
          this.loader = false;
          this.search = false;
          this.searchResponse = links;
          this.haslinks = true;
          this.link = true;
          this.state.messages.push({
            title: sentMessage,
            isBot: 0,
            time: getMessageTime(),
          });
          let newConversationResponse = NEW_CONVERSATION_MESSAGE
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            const translatedResponse = await services.translateText(joinStrings([title, newConversationResponse]), this.state.sourceLanguageCode);
            [title, newConversationResponse] = splitStrings(translatedResponse)
          }
          this.state.messages.push({
            title,
            isBot: 1,
            time: getMessageTime(),
          });

          const messageIndex = this.state.messages.length;
          this.searchResponseList[messageIndex - 1] = this.searchResponse;

          this.setState({...this.state}, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });

          this.resetConfirmationState();
          this.restartConversation(newConversationResponse);
          return;
        }
      }

      // Restart conversation for nola_flood_intent based on user's reply for some questions.
      if (
        questionTitle &&
        this.currentIntent === NOLA_FLOOD_INTENT &&
        this.state.intentQuestionsList.length &&
        isYesUtterance(message.toLowerCase())
      ) {
        let contactFloodingResponse = CONTACT_911_FOR_FLOODING
        let newConversationMessage = NEW_CONVERSATION_MESSAGE
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([contactFloodingResponse, newConversationMessage]), this.state.sourceLanguageCode);
          [contactFloodingResponse, newConversationMessage] = splitStrings(translatedResponse)
        }
        if (questionTitle === IS_STREET_FLOODED) {
          this.resetConfirmationState();
          this.restartConversation(contactFloodingResponse, sentMessage);
          this.restartConversation(newConversationMessage);
          return;

        }
      }

      // Restart conversation for Oakland vehicle intent based on user's reply for some questions.
      if (
        questionSlot &&
        this.currentIntent === OAKLAND_ABANDONED_VEHICLE_INTENT &&
        this.state.intentQuestionsList.length &&
        isYesUtterance(message.toLowerCase())
      ) {
        let oaklandAbandonedVehicleResponse = OAKLAND_ABANDONED_VEHICLE_MESSAGE
        let newConversationMessage = NEW_CONVERSATION_MESSAGE
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([oaklandAbandonedVehicleResponse, newConversationMessage]), this.state.sourceLanguageCode);
          [oaklandAbandonedVehicleResponse, newConversationMessage] = splitStrings(translatedResponse)
        }
        if (questionTitle === IS_PRIVATE_PROPERTY) {
          this.resetConfirmationState();
          this.restartConversation(oaklandAbandonedVehicleResponse, sentMessage);
          this.restartConversation(newConversationMessage);
          return;

        }
      }

      // Restart conversation for cb_covid_non_compliance_intent based on user's reply for some questions.
      if (
        questionSlot &&
        this.currentIntent === COVID_NON_COMPLIANCE_INTENT &&
        this.state.intentQuestionsList.length &&
        isYesUtterance(message.toLowerCase())
      ) {
        let covidLargeGatheringResponse = COVID_LARGE_GATHERING
        let newConversationMessage = NEW_CONVERSATION_MESSAGE
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          const translatedResponse = await services.translateText(joinStrings([questionSlot, covidLargeGatheringResponse, newConversationMessage]), this.state.sourceLanguageCode);
          [questionSlot, covidLargeGatheringResponse, newConversationMessage] = splitStrings(translatedResponse)
        }
        if (questionSlot === IS_LARGE_GATHERING_SLOT) {
          this.resetConfirmationState();
          this.restartConversation(covidLargeGatheringResponse, sentMessage);
          this.restartConversation(newConversationMessage);
          return;

        }
      }

      // Parking Permit Intent Flow.
      if (
        questionSlot &&
        this.currentIntent === PARKING_PERMIT_INTENT &&
        this.state.intentQuestionsList.length
      ) {
        let {title, links} = hasParkingPermitIntentFlowLinks(
          questionSlot,
          message
        );
        if (title) {
          this.loader = false;
          this.search = false;
          this.searchResponse = links;
          this.haslinks = true;
          this.link = true;
          this.state.messages.push({
            title: sentMessage,
            isBot: 0,
            time: getMessageTime(),
          });
          let newConversationResponse = NEW_CONVERSATION_MESSAGE
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            const translatedResponse = await services.translateText(joinStrings([title, newConversationResponse]), this.state.sourceLanguageCode);
            [title, newConversationResponse] = splitStrings(translatedResponse)
          }

          this.state.messages.push({
            title,
            isBot: 1,
            time: getMessageTime(),
          });

          const messageIndex = this.state.messages.length;
          this.searchResponseList[messageIndex - 1] = this.searchResponse;

          this.setState({...this.state}, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });

          this.resetConfirmationState();
          this.restartConversation(newConversationResponse);
          return;
        }
      }

      // check for report issue confirmation message.
      // if (!isSpecialMessage) {
      //     if (this.isConfirmationRejected) {
      //         return;
      //     }
      //
      //     if (this.showConfirmationMessage && !this.isConfirmationRejected && !this.requestConfirmed) {
      //         if (isNoUtterance(message.toLowerCase())) {
      //             this.isConfirmationRejected = true;
      //             this.loader = false;
      //             App.disableMessageButton = false;
      //             this.resetConfirmationState();
      //             this.restartConversation(CONFIRMATION_REJECTED_MESSAGE, message);
      //             return;
      //         } else if (!this.requestConfirmed) {
      //             this.state.messages.push({title: message, isBot: 0, time: getMessageTime()});
      //             this.setState({...this.state}, () => {
      //                 this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
      //             });
      //             this.requestConfirmed = true;
      //             this.fetchRequestQuestions();
      //             return;
      //         }
      //     }
      // }

      if (isSpecialMessage) {
        console.log('isSpecialMessage message =>', message)
        App.disableMessageButton = false;
        this.state.messages.push({
          title: sentMessage,
          isBot: 0,
          time: getMessageTime(),
        });
        this.setState(
          {
            ...this.state,
            questionsList: [],
            questionCount: 0,
            questionNo: -1,
            intentQuestionsList: [],
          },
          () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          }
        );
        this.loader = true;
        this.resetConfirmationState();
        let newConversationResponse = START_NEW_CONVERSATION_MESSAGE
        if (this.state.followUpMessage) {
          newConversationResponse = this.state.followUpMessage;
        }
        if (this.state.sourceLanguageCode !== appLanguageCode) {
          newConversationResponse = await services.translateText(newConversationResponse, this.state.sourceLanguageCode)
        }
        services
          .getIntent(this.contactNo, this.phone_number, sentMessage)
          .then((res) => {
            if (
              res &&
              res.data &&
              (res.data as any).intent === THANK_YOU_INTENT &&
              (res.data as any).response
            ) {
              this.restartConversation((res.data as any).response);
            } else {
              this.restartConversation(newConversationResponse);
            }
          })
          .catch((err) => {
            this.restartConversation(newConversationResponse);
          });
      } else if (this.startSurvey) {
        console.log("this.startSurvey message=>", message)
        this.surveyData.push(message);
        this.state.messages.push({
          title: sentMessage,
          isBot: 0,
          time: getMessageTime(),
        });
        this.setState({...this.state}, () => {
          this.chat_space.current.scrollTop =
            this.chat_space.current.scrollHeight;
        });

        if (this.currentSurveyQuestion < this.totalSurveyQuestions) {
          this.setupSurvey();
        } else {
          let endConversationResponse = END_CONVERSATION_MESSAGE
          if (this.state.sourceLanguageCode !==  appLanguageCode) {
            endConversationResponse = await services.translateText(endConversationResponse, this.state.sourceLanguageCode)
          }
          this.endConversation = true;
          this.state.messages.push({
            title: endConversationResponse,
            isBot: 1,
            time: getMessageTime(),
          });
          this.loader = false;
          this.setState({...this.state}, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });
        }
      } else {
        if (this.state.questionNo === -2) {
          this.cancelRequest = false;
          this.contactNo = message;
          this.checkFirstUserReply();
          try {
            this.firstUserReply = message === sentMessage ? message : sentMessage;
            const firstMsg = this.firstUserReply
              ? this.firstUserReply
              : this.contactNo;
            console.log('1931 firstMsg =>',firstMsg)
            this.state.messages.push({
              title: firstMsg,
              isBot: 0,
              time: getMessageTime(),
            });
            this.loader = true;
            this.setState({...this.state}, () => {
              this.chat_space.current.scrollTop =
                this.chat_space.current.scrollHeight;
            });
            this.loader = false;
            this.setState({...this.state, questionNo: -1});
            console.log('1944 message =>',message)
            this.dialogHistory.push({
              userInput: sentMessage,
              citibot: 'Chat Bot Greeting Message!',
            });
            if (!this.firstUserReply) {
              let issueHelpMessageResponse = ISSUE_HELP_MESSAGE
              if (this.state.sourceLanguageCode !==  appLanguageCode) {
                issueHelpMessageResponse = await services.translateText(issueHelpMessageResponse, this.state.sourceLanguageCode)
              }
              this.state.messages.push({
                title: issueHelpMessageResponse,
                isBot: 1,
                time: getMessageTime(),
              });
              this.setState({...this.state}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });
            } else {
              this.loader = true;
              this.setState({...this.state}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });
              this.getIntentQuestions();
            }
          } catch (err) {
            this.state.messages.push({
              title: this.contactNo.toString(),
              isBot: 0,
              time: getMessageTime(),
            });
            this.setState({...this.state, questionNo: -2});

            let invalidPhoneResponse = ENTER_VALID_PHONE_NUMBER
            if (this.state.sourceLanguageCode !==  appLanguageCode) {
              invalidPhoneResponse = await services.translateText(invalidPhoneResponse, this.state.sourceLanguageCode)
            }
            this.state.messages.push({
              title: invalidPhoneResponse,
              isBot: 1,
              time: getMessageTime(),
            });
            this.setState({...this.state}, () => {
              this.chat_space.current.scrollTop =
                this.chat_space.current.scrollHeight;
            });
          }
        } else if (this.state.questionNo === -1 && !this.loader) {
          console.log('1944 message =>', message)
          this.firstUserReply = message === sentMessage ? message : sentMessage;
          console.log('1944 this.firstUserReply =>', this.firstUserReply)
          this.state.messages.push({
            title: this.firstUserReply,
            isBot: 0,
            time: getMessageTime(),
          });
          this.loader = true;
          this.setState({...this.state}, () => {
            this.chat_space.current.scrollTop =
              this.chat_space.current.scrollHeight;
          });
          this.dialogHistory.push({
            userInput: sentMessage,
            citibot:
              this.state.messages[this.state.messages.length - 2]['title'],
          });
          if (!this.search) {
            this.getIntentQuestions();
          } else {
            if (this.searchString) {
              this.loader = false;

              let searchQuestionResponse = SEARCH_QUESTION
              if (this.state.sourceLanguageCode !==  appLanguageCode) {
                searchQuestionResponse = await services.translateText(searchQuestionResponse, this.state.sourceLanguageCode)
              }
              this.state.messages.push({
                title: searchQuestionResponse,
                isBot: 1,
                time: getMessageTime(),
              });
              this.setState(this.state);
              this.searchString = sentMessage;
            } else {
              services
                .searchIssue(message, this.phone_number, this.contactNo)
                .then(async (res) => {
                  this.loader = false;
                  this.search = false;
                  const data = (res.data as any).data;
                  if (data === null) {
                    // this.lastQues = true;
                    this.haslinks = false;
                    this.searchResponse = data;
                    App.disableMessageButton = false;
                    let visitLinkResponse =  this.state.account_city.toLowerCase() === OAKLAND ? OAKLAND_VISIT_LINK_MESSAGE: VISIT_LINK_MESSAGE ;
                    if (this.state.sourceLanguageCode !==  appLanguageCode) {
                      visitLinkResponse = await services.translateText(visitLinkResponse, this.state.sourceLanguageCode)
                    }
                    // if (this.state.account_city.toLowerCase() === OAKLAND) {
                    //   this.state.messages.push({
                    //     title: OAKLAND_VISIT_LINK_MESSAGE,
                    //     isBot: 1,
                    //     time: getMessageTime(),
                    //   });
                    // } else {
                    //   this.state.messages.push({
                    //     title: VISIT_LINK_MESSAGE,
                    //     isBot: 1,
                    //     time: getMessageTime(),
                    //   });
                    // }
                    this.state.messages.push({
                      title: visitLinkResponse,
                      isBot: 1,
                      time: getMessageTime(),
                    });
                  } else {
                    let searchResultReadyResponse =  this.state.account_city.toLowerCase() === OAKLAND ? OAKLAND_SEARCH_RESULT_READY_MESSAGE: SEARCH_RESULT_READY_MESSAGE ;
                    if (this.state.sourceLanguageCode !==  appLanguageCode) {
                      searchResultReadyResponse = await services.translateText(searchResultReadyResponse, this.state.sourceLanguageCode)
                    }

                    // if (this.state.account_city.toLowerCase() === OAKLAND) {
                    //   this.searchResponse = data;
                    //   this.haslinks = true;
                    //   this.state.messages.push({
                    //     title: OAKLAND_SEARCH_RESULT_READY_MESSAGE,
                    //     isBot: 1,
                    //     time: getMessageTime(),
                    //   });
                    // } else {
                    //   this.searchResponse = data;
                    //   this.haslinks = true;
                    //   this.state.messages.push({
                    //     title: SEARCH_RESULT_READY_MESSAGE,
                    //     isBot: 1,
                    //     time: getMessageTime(),
                    //   });
                    // }

                    this.searchResponse = data;
                    this.haslinks = true;
                    this.state.messages.push({
                      title: searchResultReadyResponse,
                      isBot: 1,
                      time: getMessageTime(),
                    });
                  }
                  this.setState({...this.state, questionNo: -1});
                  this.setState(this.state, () => {
                    this.chat_space.current.scrollTop =
                      this.chat_space.current.scrollHeight;
                  });
                })
                .catch(async (err) => {
                  this.lastQues = true;
                  this.loader = false;
                  this.link = false;
                  App.disableMessageButton = false;
                  let visitLinkResponse =  this.state.account_city.toLowerCase() === OAKLAND ? OAKLAND_VISIT_LINK_MESSAGE: VISIT_LINK_MESSAGE ;
                  if (this.state.sourceLanguageCode !==  appLanguageCode) {
                    visitLinkResponse = await services.translateText(visitLinkResponse, this.state.sourceLanguageCode)
                  }
                  // if (this.state.account_city.toLowerCase() === OAKLAND) {
                  //   this.state.messages.push({
                  //     title: OAKLAND_VISIT_LINK_MESSAGE,
                  //     isBot: 1,
                  //     time: getMessageTime(),
                  //   });
                  // } else {
                  //   this.state.messages.push({
                  //     title: VISIT_LINK_MESSAGE,
                  //     isBot: 1,
                  //     time: getMessageTime(),
                  //   });
                  // }
                  this.state.messages.push({
                    title: visitLinkResponse,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.setState(this.state);
                });
            }
          }
        } else {
          if (this.state.questionNo < this.state.questionCount) {
            console.log('2076 message =>',message)
            this.storeAddress(message === sentMessage ? message : sentMessage);
            this.state.messages.push({
              title: message === sentMessage ? message : sentMessage,
              isBot: 0,
              time: getMessageTime(),
            });
            this.setState({...this.state}, () => {
              this.chat_space.current.scrollTop =
                this.chat_space.current.scrollHeight;
            });

            if (!this.continueConvQues) {
              this.dialogHistory.push({
                userInput: message === sentMessage ? message : sentMessage,
                citibot:
                  this.state.messages[this.state.messages.length - 2]['title'],
              });
            }

            const question: string =
              this.state.questionsList?.[
              this.state.questionNo - 1
                ]?.['title'];
                if (
                  this.state.doubleCheckName &&
                  this.state.questionsList[this.state.questionNo - 1]['slot'] ===
                  'name'
                ) {
                  if (!["anon", "anonymous", "n/a", "none", "no", "na"].includes(
                    message.toLowerCase()
                  )) {
                  services
                    .getIntent('randmomid', this.phone_number, message).then((res) => {
                      const name = res?.data.response;
                      const inputString = name;
                      const nameRegex = /Hello\s+([^\s!]+(?:\s+[^\s!]+)*)\s*!/i;
                      const match = inputString.match(nameRegex);
                      if (match && match[1]) {
                        const extractedName = match[1];
                        this.slots.name = extractedName;
                      } else {
                        console.log("Name not found in the input string.");
                      }
                    });
                  }
                }    
            if (
              this.state.account_city.toLowerCase() === NEW_ORLEANS &&
              this.state.custom_account_intents &&
              this.currentIntent in this.state.custom_account_intents &&
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] in
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.validateEmailAddress <= 3 &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] ===
              'email'
            ) {
              this.validateEmailAddress++;
              const isValidEmailAddress = this.ValidateEmail(message);
              if (isValidEmailAddress) {
                this.addNextQuestion(message);
              } else if (this.validateEmailAddress === 4) {
                let requiredEmailAddressResponse = EMAIL_ADDRESS_IS_REQUIRED;
                let newConversationResponse = NEW_CONVERSATION_MESSAGE
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  const translatedResponse = await services.translateText(joinStrings([requiredEmailAddressResponse, newConversationResponse]), this.state.sourceLanguageCode);
                  [requiredEmailAddressResponse, newConversationResponse] = splitStrings(translatedResponse)
                }
                this.resetConfirmationState();
                this.restartConversation(requiredEmailAddressResponse);
                this.restartConversation(newConversationResponse);
                return;
              } else {
                let invalidEmailResponse =ENTER_VALID_EMAIL_ADDRESS
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidEmailResponse = await services.translateText(invalidEmailResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidEmailResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({...this.state}, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else if (
              this.currentIntent === BALTIMORE_ILLEGAL_DUMPING_INTENT &&
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_IS_SEE_DUMPING
            ) {
              if (message.toLowerCase() === 'yes') {
                this.addNextQuestion(message)
              } else if (message.toLowerCase() === 'no') {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.currentIntent === BALTIMORE_ILLEGAL_DUMPING_INTENT &&
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_IS_SEE_INDIVIDUAL
            ) {
              if (message.toLowerCase() === 'yes') {
                this.addNextQuestion(message)
              } else if (message.toLowerCase() === 'no') {
                await this.askRequiredQuestion(message);
              }
            } else if (
              this.currentIntent === BALTIMORE_ILLEGAL_DUMPING_INTENT &&
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_IS_VEHICLE_INVOLVED
            ) {
              if (message.toLowerCase() === 'yes') {
                this.addNextQuestion(message);
              } else if (message.toLowerCase() === 'no') {
                this.handleTerminatingMessageFunc(message)
              }
            } else if (
              this.currentIntent === BALTIMORE_ILLEGAL_DUMPING_INTENT &&
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_VEHICLE_MAKER
            ) {
              if (message.toLowerCase() === 'other') {
                this.addNextQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message)
              }
            } else if (this.state.account_city.toLowerCase() === ROSEVILLE &&
              this.currentIntent === ROSEVILLE_RESIDENTIAL_PARKING_PREMIT_INTENT &&
              question === ROSEVILLE_HOME_OWNER_QUES) {
              if (message.toLowerCase() === 'yes') {
                await this.askNonRequiredQuestion(message);
              } else {
                this.addNextQuestion(message);
              }
            } 
            else if (
              this.currentIntent === BALT_GRASS_MOWING_HCDSPROP_INTENT &&
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_RODENT_QUESTION
            ) {
              if (message.toLowerCase() === 'yes') {
                await this.askNonRequiredQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              message === 'Short timing 10-13'
            ) {
              await this.triggerTheNewIntent('dependentsignal');
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              this.currentIntent === BALT_TRAFFIC_SIGNAL_TRSIWATI_INTENT &&
              question === BALT_SIGNAL_TYPE_QUESTION
            ) {
              if (message === 'Pedestrian') {
                const newQuestionList = this.state.questionsList.slice(0, -2);
                const newQuestionCount = newQuestionList.length;
                this.setState({
                  ...this.state,
                  questionsList: newQuestionList,
                  questionCount: newQuestionCount
                }, () => {
                  this.addNextQuestion(message);
                });
              } else {
                const indexesToFilter = [0, 3, 4];
                const firstAndLastTwoQuestion = this.state.questionsList.filter((_element, index) => {
                  return indexesToFilter.includes(index);
                })
                const newQuestionCount = firstAndLastTwoQuestion.length;
                this.setState({
                  ...this.state,
                  questionsList: firstAndLastTwoQuestion,
                  questionCount: newQuestionCount
                }, () => {
                  this.addNextQuestion(message);
                });
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_WATER_COMING_FROM_QUESTION
            ) {
              if (message === "Water meter") {
                await this.triggerTheNewIntent("dependentwatermeter");
              } else {
                this.addNextQuestion(message);
              }
            } else if (this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_RAINING_QUESTION
            ) {
              if (message.toLowerCase() === 'yes') {
                await this.triggerTheNewIntent("dependentraining");
              } else {
                this.addNextQuestion(message);
              }
            } else if (this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_ILLEGALY_PARKED_QUESTION) {
              if (message === 'Expired tags' || message === 'No tags' ||
                message === 'Parked longer than 48 hours' || message === 'Possible abandoned vehicle') {
                await this.triggerTheNewIntent('forty eight hours parking issue');
                } else {
                  this.addNextQuestion(message);
                }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_VEHICLE_LOCATION
            ) {
              if (message === 'Sidewalk') {
                await this.triggerTheNewIntent('parking complaint');
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_STREET_LIGHT_QUESTION
            ) {
              if (message === 'Park or playground') {
                await this.triggerTheNewIntent('dependentparklight');
              } else if (message === 'Rear') {
                await this.triggerTheNewIntent('dependentrearlight');
              } else if (message === 'Side' || message === 'Front') {
                await this.triggerTheNewIntent('dependentsidelight');
              }
              else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_LIGHT_PROB
            ) {
              if (message === 'Light Inadequate or too bright') {
                await this.triggerTheNewIntent('dependentsteerlightbright');
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_POTHOLE_LOC
            ) {
              if (message === 'Footway') {
                await this.triggerTheNewIntent('dependentpotholefoot');
              } else if (message === 'Curb') {
                await this.triggerTheNewIntent("dependentpotholecurb");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_POTHOLE_ISSUE
            ) {
              if (message === 'Steel plate') {
                await this.triggerTheNewIntent('dependentpotholesteel');
              } else if (message === 'Manhole' ||  message === 'Valve' || message === 'Water meter') {
                await this.triggerTheNewIntent("dependentpotholevalve"); 
              }
              else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_HAZARDOUS_CONDITION_QUES
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_IS_ANYONE_INJURED_QUES
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_TRASH_CAN_REQ_QUES
            ) {
              if (message === 'Request can removal' || message === 'Request new can' ||
              message === 'Missing - request replacement can') {
                await this.triggerTheNewIntent("dependentmissingcorner");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_TRASH_CAN_LOC_QUES
            ) {
              if (message === 'In a park') {
                await this.triggerTheNewIntent('dependenttrashpark');
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_PARK_TRASH_CAN_CONCERN_QUES
            ) {
              if (message === 'Can request' || message === 'Trash on park grounds') {
                await this.triggerTheNewIntent("park maintenance");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_PARK_MAINTENANCE_QUES
            ) {
              if (message === 'Landscaping') {
                const truncatedQuestionsList = this.state.questionsList.filter(
                  (_question, index) => index !== 6 && index !== 7
                );
                const newQuestionCount = truncatedQuestionsList.length;
                this.setState({
                  ...this.state,
                  questionsList: truncatedQuestionsList,
                  questionCount: newQuestionCount
                }, () => {
                  this.addNextQuestion(message);
                });
              } else if (message === 'Trash') {
                const truncatedQuestionsList = this.state.questionsList.filter(
                  (_question, index) => index !== 4 && index !== 5
                );
                const newQuestionCount = truncatedQuestionsList.length;
                this.setState({
                  ...this.state,
                  questionsList: truncatedQuestionsList,
                  questionCount: newQuestionCount
                }, () => {
                  this.addNextQuestion(message);
                });
              } else if (message === 'Other') {
                const truncatedQuestionsList = this.state.questionsList.filter(
                  (_question, index) => index !== 4 && index !== 5 && index !== 6
                  && index !== 7
                );
                const newQuestionCount = truncatedQuestionsList.length;
                this.setState({
                  ...this.state,
                  questionsList: truncatedQuestionsList,
                  questionCount: newQuestionCount
                }, () => {
                  this.addNextQuestion(message);
                });
              } else if (message === 'Additional trash cans') {
                await this.triggerTheNewIntent("dependenttrashpark");
              }
              else {
                this.askRequiredQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_PLANNED_ACTIVITY_SCHEDULED
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.askRequiredQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_PERMITTED_EVENT
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_WHATS_NEED_ATTENTION_QUES
            ) {
              if (message === 'Grass') {
                await this.triggerTheNewIntent("park dependent");
              } else {
                this.askRequiredQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_TYPE_OF_TRASH_QUES
            ) {
              if (message === 'Overflowing can') {
                await this.triggerTheNewIntent("dependenttrashpark");
              } else if (message === 'Bulk') {
                await this.triggerTheNewIntent("dependenttrashbulksat");
              }
              else {
                this.askRequiredQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_TRASH_FROM_VEHICLE
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("illegal dumping");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'What type of noise ?'
            ) {
              if (message === 'Construction' || message === 'A/C, HVAC units, generators'
                || message === 'Amplified music' || message === 'Other' || message === 'Picketers') {
                this.dynamicHandlingNoiseType(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Is this issue concerning a private residence or a commercial and/or licensed business?'
            ) {
              if (message === 'Business') {
                this.askNonRequiredQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Name of the business :'
            ) {
              this.askNonRequiredQuestion(message);
            } 
            else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              this.currentIntent === BALTIMORE_NOISE_INTENT &&
              question === 'What is the issue?'
            ) {
              if (message === 'Other') {
                this.addNextQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() ===  BALTIMORE &&
              question === 'Are you the owner of this property?'
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("dependentgraffitiowner");
              } else {
                await this.triggerTheNewIntent("dependentgraffitiownerno");
              }
            } 
            else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Where is the graffiti located?'
            ) {
              if (message === 'Public property') {
                await this.triggerTheNewIntent("dependentgraffitipublic");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === "Do you know the animal's owner?"
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.askRequiredQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === "Owner's name?"
            ) {
              if (message.toLowerCase() !== 'other') {
                this.askNonRequiredQuestion(message);
              }
            } else if(
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Is this concern about a domestic animal?'
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("domesticanimal");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Is the animal trapped in a vacant building?'
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("animaltrappedyes");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              this.currentIntent === 'baltimorebot_dep_animal_services_zzzhcomp' &&
              question === 'What type of animal is it?'
            ) {
              if (message === 'Cat' || message === 'Dog') {
                await this.triggerTheNewIntent("captured");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Has the animal bitten a human?'
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("bitten");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Did that person receive medical care?'
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Where were they treated?'
            ) {
              if (message !== 'Other') {
                this.askNonRequiredQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Was there a police report?'
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Do you know where the animal lives?'
            ) {
              if (message === 'Yes') {
                this.askNonRequiredQuestion(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === 'Has the animal bitten a pet?'
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("attack");
              } else {
                this.addNextQuestion(message);
              }
            } else if (
              questionTitle &&
              this.currentIntent === "denver_encampment_issue_intent" &&
              this.state.intentQuestionsList.length &&
              questionTitle === "Is there a police, fire, or medical emergency happening right now?" &&
              isYesUtterance(message.toLowerCase())
            ) {
              let contact911Message = "This is an emergency. Please call 911";
              let newConversationMessage = NEW_CONVERSATION_MESSAGE
              if (this.state.sourceLanguageCode !== appLanguageCode) {
                const translatedResponse = await services.translateText(joinStrings([contact911Message, newConversationMessage]), this.state.sourceLanguageCode);
                [contact911Message, newConversationMessage] = splitStrings(translatedResponse)
              }
              this.resetConfirmationState();
              this.restartConversation(contact911Message);
              this.restartConversation(newConversationMessage);
              return;
            }
            else if (this.currentIntent === BAKERSFIELD_PROPERTY_VIOLATION_INTENT &&
            this.state.account_city.toLowerCase() === BAKERSFIELD && question === 'Property Violation Type:') {
              if (message === 'Abandoned Furniture & Appliances') {
                this.handleBakersfieldFurnitureCase(message);
              } else if (message === 'Broken Fence') {
                this.handleBakersfieldFenceCase(message);
              } else if (message === 'Green Pool') {
                this.handleBakersfieldPoolCase(message);
              } else if (message === 'Not Parked on Pavement') {
                this.handleBakersfieldPavementCase(message);
              } else if (message === 'Overgrown Grass & Weeds' || message === 'Trash & Litter in Yard') {
                this.handleBakersfieldGrassCase(message);
              } else if (message === 'Person in Posted Building') {
                this.handleBakersfiedPersonCase(message);
              } else if (message === 'Sign or Banner') {
                this.askDescriptionQuesForBakersfield(message);
              } else {
                this.handleBakersfieldSquatterCase(message);
              }
          } else if(
            this.state.account_city.toLocaleLowerCase() === ROCHESTER && 
            this.state.intentQuestionsList.length && 
            this.state.questionsList[this.state.questionNo - 1]['slot'] === ROCHESTER_IS_MN_PLATE_SLOT &&
            isYesUtterance(message)
          ){
              const whatStateIndex = this.state.questionsList.findIndex(question=>question['slot']===ROCHESTER_WHAT_STATE_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(whatStateIndex,1)
              if(whatStateIndex> -1){
                this.setState({
                  ...this.state,
                  questionCount: this.state.questionCount - 1,
                  questionsList: newQuestionList
                },()=>{
                  this.addNextQuestion(message)
                })
              }
          }
            else if (
              this.state.intentCanBeAnonymous[this.currentIntent] &&
              ["anon", "anonymous", "n/a", "none", "no", "NA", "na"].includes(
                message.toLowerCase()
              ) && (this.state.questionsList[this.state.questionNo]['slot'] ===
              'phone_number' || this.state.questionsList[this.state.questionNo]['slot'] ===
              'email')
            ) {
              this.anonymousFlow(message);
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_VEHICLE_CONCERN_SLOT &&
              message === "Inoperative Vehicle"
            ) {
              const vehicleConcernSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_VEHICLE_CONCERN_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(vehicleConcernSlotIndex + 2, 2)
              if(vehicleConcernSlotIndex > -1){
                this.setState({
                  ...this.state,
                  questionCount: this.state.questionCount - 1,
                  questionsList: newQuestionList
                },()=>{
                  this.addNextQuestion(message)
                })
              }
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_VEHICLE_CONCERN_SLOT &&
              message === "Parking on Unimproved Surface"
            ) {
              this.askNonRequiredQuestion(message);
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_UNIMPROVED_VEHICLE_SLOT
            ) {
              const vehicleConcernSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_UNIMPROVED_VEHICLE_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(vehicleConcernSlotIndex + 1, 1)
              if(vehicleConcernSlotIndex > -1){
                this.setState({
                  ...this.state,
                  questionCount: newQuestionList.length,
                  questionsList: newQuestionList,
                  questionNo: this.state.questionNo - 1
                },()=>{
                  this.addNextQuestion(message)
                })
              }
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_VEHICLE_CONCERN_SLOT &&
              message === "Vehicle Weight Exceeded"
            ) {
              const vehicleConcernSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_VEHICLE_CONCERN_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(vehicleConcernSlotIndex + 1, 2)
              if(vehicleConcernSlotIndex > -1){
                this.setState({
                  ...this.state,
                  questionCount: this.state.questionCount - 1,
                  questionsList: newQuestionList
                },()=>{
                  this.addNextQuestion(message)
                })
              }
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWVILLE_POTHOLE_SLOT &&
              message === "Basketball Goal Placement"
            ) {
              let basketBallMessage = "Report a concern with a basketball goal in a public right-of-way";
              let newConversationMessage = NEW_CONVERSATION_MESSAGE
              if (this.state.sourceLanguageCode !== appLanguageCode) {
                const translatedResponse = await services.translateText(joinStrings([basketBallMessage, newConversationMessage]), this.state.sourceLanguageCode);
                [basketBallMessage, newConversationMessage] = splitStrings(translatedResponse)
              }
              this.resetConfirmationState();
              this.restartConversation(basketBallMessage);
              this.restartConversation(newConversationMessage);
              return;
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_TRAFFIC_SIGNAL_CONCERN_SLOT &&
              message === "Traffic Signal"
            ) {
              this.askNonRequiredQuestion(message);
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_TREE_FALLEN_SLOT &&
              isYesUtterance(message)
            ) {
              this.triggerTheNewIntent("fallen tree on sidewalk", "randomID");
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_TRASH_CART_CONCERN_SLOT &&
              message === "Recycle Cart"
            ) {
              const trashCartConcernSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_TRASH_CART_CONCERN_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(trashCartConcernSlotIndex + 2, 6)
              if(trashCartConcernSlotIndex > -1){
                this.setState({
                  ...this.state,
                  questionCount: newQuestionList.length,
                  questionsList: newQuestionList
                },()=>{
                  this.addNextQuestion(message)
                })
              }
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_RECYCLE_LOC_SLOT
            ) {
              if (message === "Commercial") {
                const recycleLocSlotIndex = this.state.originalQuestionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_RECYCLE_LOC_SLOT)
                const newQuestionList = [...this.state.originalQuestionsList];
                newQuestionList.splice(recycleLocSlotIndex + 2, 5)
                if(recycleLocSlotIndex > -1){
                  this.setState({
                    ...this.state,
                    questionCount: newQuestionList.length,
                    questionsList: newQuestionList
                  },()=>{
                    this.addNextQuestion(message)
                  })
                }
              } else {
                const recycleLocSlotIndex = this.state.originalQuestionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_RECYCLE_LOC_SLOT)
                const newQuestionList = [...this.state.originalQuestionsList];
                newQuestionList.splice(recycleLocSlotIndex + 1, 1);
                const recycleLocIssueTypeSlotIndex = newQuestionList.findIndex(slot => slot['slot'] === LEWISVILLE_RECYCLE_ISSUE_SLOT)
                newQuestionList.splice(recycleLocIssueTypeSlotIndex + 1, 4)
                if(recycleLocIssueTypeSlotIndex > -1){
                  this.setState({
                    ...this.state,
                    questionCount: newQuestionList.length,
                    questionsList: newQuestionList
                  },()=>{
                    this.addNextQuestion(message)
                  })
                }
              }
            } 
            else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_TRASH_CART_CONCERN_SLOT &&
              message === "Trash Cart"
            ) {
              const trashCartConcernSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_TRASH_CART_CONCERN_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(trashCartConcernSlotIndex + 1, 3);
              if(trashCartConcernSlotIndex > -1){
                this.setState({
                  ...this.state,
                  questionCount: newQuestionList.length,
                  questionsList: newQuestionList
                },()=>{
                  this.addNextQuestion(message)
                })
              }
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_TRASH_CART_LOC_SLOT
            ) {
              if (message === "Commercial") {
                const trashCartCommLocSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_TRASH_CART_LOC_SLOT)
                const newQuestionList = [...this.state.questionsList];
                newQuestionList.splice(trashCartCommLocSlotIndex + 2, 2);
                if(trashCartCommLocSlotIndex > -1){
                  this.setState({
                    ...this.state,
                    questionsList: newQuestionList,
                    questionCount: newQuestionList.length,
                  },()=>{
                    this.addNextQuestion(message)
                  })
                }
              } else {
                const trashCartCommLocSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_TRASH_CART_LOC_SLOT)
                const newQuestionList = [...this.state.questionsList];
                newQuestionList.splice(trashCartCommLocSlotIndex + 1, 1);
                const residentialIssueLocIndex = newQuestionList.findIndex(slot => slot['slot'] === LEWISVILLE_TRASH_CART_RESIDENTIAL_LOC_ISSUE_SLOT);
                newQuestionList.splice(residentialIssueLocIndex + 1, 1);
                if(trashCartCommLocSlotIndex > -1){
                  this.setState({
                    ...this.state,
                    questionsList: newQuestionList,
                    questionCount: newQuestionList.length,
                  },()=>{
                    this.addNextQuestion(message)
                  })
                }
              }
            } else if (
              this.state.account_city.toLowerCase() === LEWISVILLE &&
              this.state.intentQuestionsList.length && 
              this.state.questionsList[this.state.questionNo - 1]['slot'] === LEWISVILLE_TRASH_CART_CONCERN_SLOT &&
              message === "Trash/Recycling Container Placement"
            ) {
              const trashCartConcernSlotIndex = this.state.questionsList.findIndex(slot=>slot['slot'] === LEWISVILLE_TRASH_CART_CONCERN_SLOT)
              const newQuestionList = [...this.state.questionsList];
              newQuestionList.splice(trashCartConcernSlotIndex + 1, 6);
              if(trashCartConcernSlotIndex > -1){
                this.setState({
                  ...this.state,
                  questionCount: newQuestionList.length,
                  questionsList: newQuestionList
                },()=>{
                  this.addNextQuestion(message)
                })
              }
            }
            else if (
              this.state.account_city.toLowerCase() === NEW_ORLEANS &&
              this.state.custom_account_intents &&
              this.currentIntent in this.state.custom_account_intents &&
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] in
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.validateAddress <= 3 &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] ===
              'address'
            ) {
              this.validateAddress++;
              this.loader = true;
              services
                .verifyAddress(
                  message,
                  this.state.account_city,
                  this.state.account_region
                )
                .then(async(res) => {
                  this.loader = false;
                  const data = (res.data as any).data;
                  if (data) {
                    this.addNextQuestion(message);
                  } else if (this.validateAddress === 4) {
                    let requiredAddressResponse = ADDRESS_IS_REQUIRED;
                    let newConversationResponse = NEW_CONVERSATION_MESSAGE
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      const translatedResponse = await services.translateText(joinStrings([requiredAddressResponse, newConversationResponse]), this.state.sourceLanguageCode);
                      [requiredAddressResponse, newConversationResponse] = splitStrings(translatedResponse)
                    }
                    this.resetConfirmationState();
                    this.restartConversation(requiredAddressResponse);
                    this.restartConversation(newConversationResponse);
                    return;
                  } else {
                    let invalidAddressResponse = ENTER_VALID_ADDRESS.replace("{address}", message);
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                    }
                    this.state.messages.push({
                      title: invalidAddressResponse,
                      isBot: 1,
                      time: getMessageTime(),
                    });
                    this.setState({...this.state}, () => {
                      this.chat_space.current.scrollTop =
                        this.chat_space.current.scrollHeight;
                    });
                  }
                })
                .catch(async(e) => {
                  this.loader = false;
                  if (this.validateAddress === 4) {
                    let requiredAddressResponse = ADDRESS_IS_REQUIRED;
                    let newConversationResponse = NEW_CONVERSATION_MESSAGE
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      const translatedResponse = await services.translateText(joinStrings([requiredAddressResponse, newConversationResponse]), this.state.sourceLanguageCode);
                      [requiredAddressResponse, newConversationResponse] = splitStrings(translatedResponse)
                    }
                    this.resetConfirmationState();
                    this.restartConversation(requiredAddressResponse);
                    this.restartConversation(newConversationResponse);
                    return;
                  }
                  let invalidAddressResponse = ENTER_VALID_ADDRESS.replace("{address}", message);
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                  }
                  this.state.messages.push({
                    title: invalidAddressResponse,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.setState({...this.state}, () => {
                    this.chat_space.current.scrollTop =
                      this.chat_space.current.scrollHeight;
                  });
                });
            } else if (
              this.state.account_city.toLowerCase() === NEW_ORLEANS &&
              this.state.custom_account_intents &&
              this.currentIntent in this.state.custom_account_intents &&
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] in
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] ===
              'phone_number' &&
              this.validatePhoneNumber <= 3
            ) {
              this.validatePhoneNumber++;
              const isValidPhoneNumber = this.checkPhoneNumberValidity(message);
              if (isValidPhoneNumber) {
                this.addNextQuestion(message);
              } else if (this.validatePhoneNumber === 4) {
                let requiredPhoneResponse = PHONE_IS_REQUIRED;
                let newConversationResponse = NEW_CONVERSATION_MESSAGE
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  const translatedResponse = await services.translateText(joinStrings([requiredPhoneResponse, newConversationResponse]), this.state.sourceLanguageCode);
                  [requiredPhoneResponse, newConversationResponse] = splitStrings(translatedResponse)
                }
                this.resetConfirmationState();
                this.restartConversation(requiredPhoneResponse);
                this.restartConversation(newConversationResponse);
                return;
              } else {
                let invalidPhoneResponse = ENTER_VALID_PHONE_NUMBER
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidPhoneResponse = await services.translateText(invalidPhoneResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidPhoneResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({...this.state}, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else if (
              typeof this.state.questionsList[0] != 'undefined' &&
              !this.validateEmailAddress &&
              this.state.questionsList[
              this.state.intentQuestionsList.length - 1
                ]['type'] === 'email'
            ) {
              this.validateEmailAddress = 1;
              const isValidEmailAddress = this.ValidateEmail(message);
              if (isValidEmailAddress) {
                this.addNextQuestion(message);
              } else {
                let invalidEmailResponse = ENTER_VALID_EMAIL
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidEmailResponse = await services.translateText(invalidEmailResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidEmailResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({...this.state}, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else if (typeof this.state.questionsList[0] != 'undefined' &&
            this.validateEmailAddress === 1 &&
            this.state.questionsList[
            this.state.intentQuestionsList.length - 1
              ]['type'] === 'email' && this.state.allowNoEmail) {
              let invalidEmailResponse = this.state.emailValidationMessage;
              if (this.state.sourceLanguageCode !== appLanguageCode) {
                invalidEmailResponse = await services.translateText(invalidEmailResponse, this.state.sourceLanguageCode)
              }
              this.state.messages.push({
                title: invalidEmailResponse,
                isBot: 1,
                time: getMessageTime(),
              });
              this.setState({...this.state}, () => {
                this.chat_space.current.scrollTop =
                  this.chat_space.current.scrollHeight;
              });
              const noReplyEmail = "noreply@citibot.io";
              this.addNextQuestion(noReplyEmail);
            }  
            else if (
              typeof this.state.questionsList[0] != 'undefined' &&
              this.validateAddress < 2 &&
              this.state.questionsList[
              this.state.intentQuestionsList.length - 1
                ]['type'] === 'address'
            ) {
              this.loader = true;
              // crap else if
              this.validateAddress++;
              if (this.validateAddress === 1) {
                services
                  .verifyAddress(
                    message,
                    this.state.account_city,
                    this.state.account_region
                  )
                  .then(async (res) => {
                    this.loader = false;
                    const data = (res.data as any).data;
                    if (data) {
                      // Here added the validated result
                      this.addNextQuestion(data);
                    } else {

                      let invalidAddressResponse = ENTER_VALID_ADDRESS
                      if (this.state.sourceLanguageCode !== appLanguageCode) {
                        invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                      }
                      this.state.messages.push({
                        title: invalidAddressResponse,
                        isBot: 1,
                        time: getMessageTime(),
                      });
                      this.setState({...this.state}, () => {
                        this.chat_space.current.scrollTop =
                          this.chat_space.current.scrollHeight;
                      });
                    }
                  })
                  .catch(async (e) => {

                    let invalidAddressResponse = ENTER_VALID_ADDRESS
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                    }
                    this.state.messages.push({
                      title: invalidAddressResponse,
                      isBot: 1,
                      time: getMessageTime(),
                    });
                    this.setState({...this.state}, () => {
                      this.chat_space.current.scrollTop =
                        this.chat_space.current.scrollHeight;
                    });
                  });
              } else if (this.validateAddress === 2) { // this code will not be reached as  this.validateAddress is assigned to 1
                services
                  .verifyAddress(
                    message,
                    this.state.account_city,
                    this.state.account_region
                  )
                  .then((res) => {
                    this.loader = false;
                    if (
                      !(res.data as any).data &&
                      this.state.account_city.toLowerCase() === NEW_ORLEANS
                    ) {
                      this.createOtherTypeRequest = true;
                    }
                    this.addNextQuestion(message);
                  })
                  .catch((e) => {
                    this.addNextQuestion(message);
                  });
              }
            } 
            else if (this.state.account_city.toLowerCase() === ROSEVILLE &&
              typeof this.state.questionsList[0] != 'undefined' &&
              question.includes('Permit issuance date') &&
              this.validateRoseVilleDate < 2) {
              this.validateRoseVilleDate++;
              if (this.validateRoseVilleDate === 1) {
                const isValidDate = moment(message, 'DD-MM-YYYY', true);
                if (isValidDate.isValid()) {
                  this.addNextQuestion(isValidDate.format('DD-MM-YYYY'));
                } else {
                  let invalidDateResponse = 'Invalid date format? Please provide date format like this date-month-fullyear'
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    invalidDateResponse = await services.translateText(invalidDateResponse, this.state.sourceLanguageCode)
                  }
                  this.state.messages.push({
                    title: invalidDateResponse,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.setState({ ...this.state }, () => {
                    this.chat_space.current.scrollTop =
                      this.chat_space.current.scrollHeight;
                  });
                }
              } else {
                  const isValidDate = moment(message, 'DD-MM-YYYY', true);
                if (isValidDate.isValid()) {
                  this.addNextQuestion(isValidDate.format('DD-MM-YYYY'));
                } else {
                  this.addNextQuestion(message);
                }
              }
            } else if (this.state.account_city.toLowerCase() === ROCHESTER &&
            this.state.questionsList.length  &&
            ['issueDate', 'damageDate'].includes(currentSlot) &&
            this.validateRoseVilleDate < 2) {
            this.validateRoseVilleDate++;
            if (this.validateRoseVilleDate === 1) {
              const isValidDate = moment(message, 'MM-DD-YYYY', true);
              if (isValidDate.isValid()) {
                this.addNextQuestion(isValidDate.format('MM-DD-YYYY'));
              } else {
                let invalidDateResponse = 'Invalid date format? Please provide date format like this month-date-fullyear'
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidDateResponse = await services.translateText(invalidDateResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidDateResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({ ...this.state }, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else {
                const isValidDate = moment(message, 'MM-DD-YYYY', true);
              if (isValidDate.isValid()) {
                this.addNextQuestion(isValidDate.format('MM-DD-YYYY'));
              } else {
                this.addNextQuestion(message);
              }
            }
          }
            else if (
              typeof this.state.questionsList[0] != 'undefined' &&
              this.validatePhoneNumber < 2 &&
              question.includes('mobile number') &&
              question.includes('What is your mobile number')
            ) {
              this.validatePhoneNumber++;
              if (this.validatePhoneNumber === 1) {
                const isValidPhoneNumber =
                  this.checkPhoneNumberValidity(message);
                if (isValidPhoneNumber) {
                  this.addNextQuestion(isValidPhoneNumber);
                } else {
                  let invalidPhoneResponse = ENTER_VALID_PHONE_NUMBER
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    invalidPhoneResponse = await services.translateText(invalidPhoneResponse, this.state.sourceLanguageCode)
                  }
                  this.state.messages.push({
                    title: invalidPhoneResponse,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.setState({...this.state}, () => {
                    this.chat_space.current.scrollTop =
                      this.chat_space.current.scrollHeight;
                  });
                }
              } else {
                const isValidPhoneNumber =
                  this.checkPhoneNumberValidity(message);
                if (isValidPhoneNumber) {
                  this.addNextQuestion(isValidPhoneNumber);
                } else {
                  this.addNextQuestion(message);
                }
              }
            } else {
              this.validatePhoneNumber = 0;
              this.validateEmailAddress = 0;
              this.validateAddress = 0;
              this.validateRoseVilleDate = 0;
              this.addNextQuestion(message);
            }
          } else {
            console.log('2464 message =>', message, 'sentMessage =>', sentMessage)
            this.state.messages.push({
              title: sentMessage,
              isBot: 0,
              time: getMessageTime(),
            });
            this.setState({...this.state}, () => {
              this.chat_space.current.scrollTop =
                this.chat_space.current.scrollHeight;
            });

            this.dialogHistory.push({
              userInput: sentMessage,
              citibot:
                this.state.messages[this.state.messages.length - 2]['title'],
            });

            const question: string =
              this.state.questionsList[
              this.state.intentQuestionsList.length - 1
                ]['title'];
            if (
              this.state.account_city.toLowerCase() === NEW_ORLEANS &&
              this.state.custom_account_intents &&
              this.currentIntent in this.state.custom_account_intents &&
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] in
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] ===
              'email' &&
              this.validateEmailAddress <= 3
            ) {
              this.validateEmailAddress++;
              const isValidEmailAddress = this.ValidateEmail(message);
              if (isValidEmailAddress) {
                this.handleSubmitConfirmation(message);
              } else if (this.validateEmailAddress === 4) {
                let requiredEmailResponse = EMAIL_ADDRESS_IS_REQUIRED;
                let newConversationResponse = NEW_CONVERSATION_MESSAGE
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  const translatedResponse = await services.translateText(joinStrings([requiredEmailResponse, newConversationResponse]), this.state.sourceLanguageCode);
                  [requiredEmailResponse, newConversationResponse] = splitStrings(translatedResponse)
                }
                this.resetConfirmationState();
                this.restartConversation(requiredEmailResponse);
                this.restartConversation(newConversationResponse);
                return;
              } else {
                let invalidEmailResponse = ENTER_VALID_EMAIL_ADDRESS
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidEmailResponse = await services.translateText(invalidEmailResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidEmailResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({...this.state}, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else if (
              this.state.account_city.toLowerCase() === NEW_ORLEANS &&
              this.state.custom_account_intents &&
              this.currentIntent in this.state.custom_account_intents &&
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] in
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] ===
              'address' &&
              this.validateAddress <= 3
            ) {
              this.validateAddress++;
              this.loader = true;
              services
                .verifyAddress(
                  message,
                  this.state.account_city,
                  this.state.account_region
                )
                .then(async(res) => {
                  this.loader = false;
                  if ((res.data as any).data) {
                    this.handleSubmitConfirmation(message);
                  } else if (this.validateAddress === 4) {
                    let requiredAddressResponse = ADDRESS_IS_REQUIRED;
                    let newConversationResponse = NEW_CONVERSATION_MESSAGE
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      const translatedResponse = await services.translateText(joinStrings([requiredAddressResponse, newConversationResponse]), this.state.sourceLanguageCode);
                      [requiredAddressResponse, newConversationResponse] = splitStrings(translatedResponse)
                    }
                    this.resetConfirmationState();
                    this.restartConversation(requiredAddressResponse);
                    this.restartConversation(newConversationResponse);
                    return;
                  } else {
                    let invalidAddressResponse = ENTER_VALID_ADDRESS.replace("{address}", message);
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                    }
                    this.state.messages.push({
                      title: invalidAddressResponse,
                      isBot: 1,
                      time: getMessageTime(),
                    });
                    this.setState({...this.state}, () => {
                      this.chat_space.current.scrollTop =
                        this.chat_space.current.scrollHeight;
                    });
                  }
                })
                .catch(async(e) => {
                  this.loader = false;
                  if (this.validateAddress === 4) {
                    let requiredAddressResponse = ADDRESS_IS_REQUIRED;
                    let newConversationResponse = NEW_CONVERSATION_MESSAGE
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      const translatedResponse = await services.translateText(joinStrings([requiredAddressResponse, newConversationResponse]), this.state.sourceLanguageCode);
                      [requiredAddressResponse, newConversationResponse] = splitStrings(translatedResponse)
                    }
                    this.resetConfirmationState();
                    this.restartConversation(requiredAddressResponse);
                    this.restartConversation(newConversationResponse);
                    return;
                  }

                  let invalidAddressResponse = ENTER_VALID_ADDRESS.replace("{address}", message);
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                  }
                  this.state.messages.push({
                    title: invalidAddressResponse,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.setState({...this.state}, () => {
                    this.chat_space.current.scrollTop =
                      this.chat_space.current.scrollHeight;
                  });
                });
            } else if (
              this.state.account_city.toLowerCase() === NEW_ORLEANS &&
              this.state.custom_account_intents &&
              this.currentIntent in this.state.custom_account_intents &&
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] in
              this.state.custom_account_intents[this.currentIntent].slots &&
              this.state.questionsList[this.state.questionNo - 1]['slot'] ===
              'phone_number' &&
              this.validatePhoneNumber <= 3
            ) {
              this.validatePhoneNumber++;
              const isValidPhoneNumber = this.checkPhoneNumberValidity(message);
              if (isValidPhoneNumber) {
                this.handleSubmitConfirmation(message);
              } else if (this.validatePhoneNumber === 4) {
                let requiredPhoneResponse = PHONE_IS_REQUIRED;
                let newConversationResponse = NEW_CONVERSATION_MESSAGE
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  const translatedResponse = await services.translateText(joinStrings([requiredPhoneResponse, newConversationResponse]), this.state.sourceLanguageCode);
                  [requiredPhoneResponse, newConversationResponse] = splitStrings(translatedResponse)
                }
                this.resetConfirmationState();
                this.restartConversation(requiredPhoneResponse);
                this.restartConversation(newConversationResponse);
                return;
              } else {
                let invalidPhoneResponse = ENTER_VALID_PHONE_NUMBER;
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidPhoneResponse = await services.translateText(invalidPhoneResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidPhoneResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({...this.state}, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else if (
              typeof this.state.questionsList[0] != 'undefined' &&
              !this.validateEmailAddress &&
              this.state.questionsList[
              this.state.intentQuestionsList.length - 1
                ]['type'] === 'email'
            ) {
              this.validateEmailAddress = 1;
              const isValidEmailAddress = this.ValidateEmail(message);
              if (isValidEmailAddress) {
                this.handleSubmitConfirmation(message);
              } else {
                let invalidEmailResponse = ENTER_VALID_EMAIL
                if (this.state.sourceLanguageCode !== appLanguageCode) {
                  invalidEmailResponse = await services.translateText(invalidEmailResponse, this.state.sourceLanguageCode)
                }
                this.state.messages.push({
                  title: invalidEmailResponse,
                  isBot: 1,
                  time: getMessageTime(),
                });
                this.setState({...this.state}, () => {
                  this.chat_space.current.scrollTop =
                    this.chat_space.current.scrollHeight;
                });
              }
            } else if (
              typeof this.state.questionsList[0] != 'undefined' &&
              this.validateAddress < 2 &&
              this.state.questionsList[
              this.state.intentQuestionsList.length - 1
                ]['type'] === 'address'
            ) {
              this.loader = true;
              this.validateAddress++;
              if (this.validateAddress === 1) {
                services
                  .verifyAddress(
                    message,
                    this.state.account_city,
                    this.state.account_region
                  )
                  .then(async(res) => {
                    this.loader = false;
                    if ((res.data as any).data) {
                      const data = res.data.data;
                      // Here sending the validated result
                      this.handleSubmitConfirmation(data);
                    } else {
                      let invalidAddressResponse = ENTER_VALID_ADDRESS.replace("{address}", message);
                      if (this.state.sourceLanguageCode !== appLanguageCode) {
                        invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                      }
                      this.state.messages.push({
                        title: invalidAddressResponse,
                        isBot: 1,
                        time: getMessageTime(),
                      });
                      this.setState({...this.state}, () => {
                        this.chat_space.current.scrollTop =
                          this.chat_space.current.scrollHeight;
                      });
                    }
                  })
                  .catch(async (e) => {
                    let invalidAddressResponse = ENTER_VALID_ADDRESS.replace("{address}", message);
                    if (this.state.sourceLanguageCode !== appLanguageCode) {
                      invalidAddressResponse = await services.translateText(invalidAddressResponse, this.state.sourceLanguageCode)
                    }
                    this.state.messages.push({
                      title: invalidAddressResponse,
                      isBot: 1,
                      time: getMessageTime(),
                    });
                    this.setState({...this.state}, () => {
                      this.chat_space.current.scrollTop =
                        this.chat_space.current.scrollHeight;
                    });
                  });
              } else if (this.validateAddress === 2) {
                services
                  .verifyAddress(
                    message,
                    this.state.account_city,
                    this.state.account_region
                  )
                  .then((res) => {
                    this.loader = false;
                    if (
                      !(res.data as any).data &&
                      this.state.account_city.toLowerCase() === NEW_ORLEANS
                    ) {
                      this.createOtherTypeRequest = true;
                    }
                    this.handleSubmitConfirmation(message);
                  })
                  .catch((e) => {
                    this.handleSubmitConfirmation(message);
                  });
              }
            } else if (
              typeof this.state.questionsList[0] != 'undefined' &&
              this.validatePhoneNumber < 2 &&
              question.includes('mobile number') &&
              question.includes('What is your mobile number')
            ) {
              this.validatePhoneNumber++;
              if (this.validatePhoneNumber === 1) {
                const isValidPhoneNumber =
                  this.checkPhoneNumberValidity(message);
                if (isValidPhoneNumber) {
                  this.handleSubmitConfirmation(isValidPhoneNumber);
                } else {
                  
                  let invalidPhoneResponse = ENTER_VALID_PHONE_NUMBER;
                  if (this.state.sourceLanguageCode !== appLanguageCode) {
                    invalidPhoneResponse = await services.translateText(invalidPhoneResponse, this.state.sourceLanguageCode)
                  }
                  this.state.messages.push({
                    title: invalidPhoneResponse,
                    isBot: 1,
                    time: getMessageTime(),
                  });
                  this.setState({...this.state}, () => {
                    this.chat_space.current.scrollTop =
                      this.chat_space.current.scrollHeight;
                  });
                }
              } else {
                const isValidPhoneNumber =
                  this.checkPhoneNumberValidity(message);
                if (isValidPhoneNumber) {
                  this.handleSubmitConfirmation(message);
                } else {
                  this.handleSubmitConfirmation(message);
                }
              }
            }
            else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              (message === 'Median strip' || message === 'Grass alley' || message === 'Roadway (soft shoulder)' )) {
              await this.triggerTheNewIntent('median strip');
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              message === 'Park'
            ) {
              await this.triggerTheNewIntent('park dependent');
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              (message === 'Commercial building' || message === 'Private dwelling' || message === 'Lot')
            ) {
              await this.triggerTheNewIntent('private dwelling');
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_PROPERTY_CAUSING_NOISE
            ) {
              if (message === 'Institution' || message === 'Business' || message === 'Other') {
                await this.dynamicHandlingForPropertyType(message);
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            } else if (
              this.state.account_city.toLowerCase() === BALTIMORE &&
              question === BALT_RESTAURANT_OR_BAR_QUES
            ) {
              if (message === 'Yes') {
                await this.triggerTheNewIntent("dependentbusinessnoise");
              } else {
                this.handleTerminatingMessageFunc(message);
              }
            }
            else {
              const questionTitle =
                this.state.questionsList[
                this.state.intentQuestionsList.length - 1
                  ]['title'];
              const botQuestion = (questionTitle as string).toLowerCase();
              const questionSlot =
                this.state.questionsList[
                this.state.intentQuestionsList.length - 1
                  ]['slot'];
              if (
                this.currentIntent === WASTE_CONTAIER_INTENT &&
                botQuestion &&
                botQuestion.includes('repair') &&
                botQuestion.includes('replace') &&
                botQuestion.includes('order')
              ) {
                const nola_flow_questions = getNolaWasterContainerQuestions(
                  message,
                  this.slots['wasteType']
                );

                this.setState(
                  {
                    ...this.state,
                    questionsList: [
                      ...this.state.questionsList,
                      ...nola_flow_questions,
                    ],
                    questionCount:
                      this.state.questionCount + nola_flow_questions.length,
                  },
                  () => {
                    this.addNextQuestion(message);
                  }
                );
              } else if (
                this.currentIntent === NOLA_TREE_INTENT &&
                questionTitle === NOLA_TREE_SERVICE_TYPE
              ) {
                const nola_flow_questions = getNolaTreeIntentQuestions(message);

                this.setState(
                  {
                    ...this.state,
                    questionsList: [
                      ...this.state.questionsList,
                      ...nola_flow_questions,
                    ],
                    questionCount:
                      this.state.questionCount + nola_flow_questions.length,
                  },
                  () => {
                    this.addNextQuestion(message);
                  }
                );
              } else if (
                this.currentIntent === WASTE_PICKUP_INTENT &&
                questionSlot === SLOT_IS_BULK
              ) {
                const nola_flow_questions = getNolaWastePickupQuestions(
                  isYesUtterance(message) || false,
                  this.slots['wasteType']
                );

                this.setState(
                  {
                    ...this.state,
                    questionsList: [
                      ...this.state.questionsList,
                      ...nola_flow_questions,
                    ],
                    questionCount:
                      this.state.questionCount + nola_flow_questions.length,
                  },
                  () => {
                    this.addNextQuestion(message);
                  }
                );
              } else if (
                this.currentIntent === PUBLIC_PROPERTY_INTENT &&
                questionSlot === SLOT_USER_MESSAGE
              ) {
                this.loader = true;
                services
                  .getIntent(this.contactNo, this.phone_number, message)
                  .then((res: any) => {
                    this.loader = false;
                    this.setState({...this.state, showMessageButton: false});
                    if (res.data.slots) {
                      const intentSlots = res.data.slots;
                      if (intentSlots.propertyType) {
                        this.slots['propertyType'] = intentSlots.propertyType;
                      }
                      if (intentSlots.issueType) {
                        this.slots['issueType'] = intentSlots.issueType;
                      }
                      if (intentSlots.damageType) {
                        this.slots['damageType'] = intentSlots.damageType;
                      }
                      if (intentSlots.wasteType) {
                        this.slots['wasteType'] = intentSlots.wasteType;
                      }
                      if (intentSlots.startStop) {
                        this.slots['startStop'] = intentSlots.startStop;
                      }
                      if (intentSlots.treeServiceType) {
                        this.slots['treeServiceType'] =
                          intentSlots.treeServiceType;
                      }
                      if (intentSlots.pestType) {
                        this.slots['pestType'] = intentSlots.pestType;
                      }
                      if (intentSlots.floodingType) {
                        this.slots['floodingType'] = intentSlots.floodingType;
                      }
                      if (intentSlots.cave_in_phrase) {
                        this.slots['cave_in_phrase'] =
                          intentSlots.cave_in_phrase;
                      }
                      if (intentSlots.permitType) {
                        this.slots['permitType'] = intentSlots.permitType;
                      }
                      if (intentSlots.yard_item) {
                        this.slots['yard_item'] = intentSlots.yard_item;
                      }
                    }
                    this.setState({...this.state});
                    this.handleSubmitConfirmation(message);
                  })
                  .catch((err) => {
                    if (!this.cancelRequest) this.askIssueAgain();
                  });
              } else {
                this.validatePhoneNumber = 0;
                this.validateEmailAddress = 0;
                this.validateAddress = 0;
                this.validateRoseVilleDate = 0;
                this.validateQuestion = 0;
                this.handleSubmitConfirmation(message);
              }
            }
          }
        }
      }
    }
  };

  handleBakersfieldSquatterCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 6 &&
          index !== 7 && index !== 8 && index !== 9 && index !== 10
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 7 && index !== 8 && index !== 9 &&
          index !== 10 && index !== 11 && index !== 12
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  // This is for bakersfield when violation type eq to sign or banner
  askDescriptionQuesForBakersfield(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 3 && index !== 4 && index !== 5 && index !== 6 &&
          index !== 7 && index !== 8 && index !== 9 && index !== 10 && index !== 11 && index !== 12
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 5 && index !== 6 && index !== 7 && index !== 8 && index !== 9 &&
          index !== 10 && index !== 11 && index !== 12 && index !== 13 && index !== 14
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  handleBakersfiedPersonCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 6 &&
          index !== 7 && index !== 8 && index !== 9 && index !== 10 && index !== 12
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 7 && index !== 8 &&
          index !== 9 && index !== 10 && index !== 11 && index !== 12 && index !== 14
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  handleBakersfieldGrassCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.slice(0, 4)
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.slice(0, 6)
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  handleBakersfieldPavementCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 6 &&
          index !== 11 && index !== 12
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 7 && index !== 8 &&
          index !== 13 && index !== 14
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  handleBakersfieldPoolCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 7 &&
          index !== 8 && index !== 9 && index !== 10 && index !== 11 && index !== 12
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 7 && index !== 9 &&
          index !== 10 && index !== 11 && index !== 12 && index !== 13 && index !== 14
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  handleBakersfieldFenceCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 4 && index !== 6 && index !== 7 &&
          index !== 8 && index !== 9 && index !== 10 && index !== 11 && index !== 12 && index !== 13
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 8 && index !== 9 &&
          index !== 10 && index !== 11 && index !== 12 && index !== 13 && index !== 14 &&
          index !== 15
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  // dynamic handling for bakersfield property violation slot
  handleBakersfieldFurnitureCase(message: string) {
    let newArray: any = {};
    if (this.slots?.name?.toLowerCase() === 'anonymous') {
      const truncatedQuestionsList = this.state.questionsList.slice(0, 5)
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.slice(0, 7)
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  //dynamic handling for baltimore noise type intent
  dynamicHandlingNoiseType(message:string) {
    let newArray:any = {};
    if (message === 'Construction' || message === 'A/C, HVAC units, generators'
      || message === 'Amplified music') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 7 && index !== 9 &&
          index !== 10 && index !== 11
      );
      newArray = [...truncatedQuestionsList];
    } else if (message === 'Other') {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 5 && index !== 7 && index !== 8 && index !== 9 &&
          index !== 10 && index !== 11
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.questionsList.filter(
        (_question, index) => index !== 6 && index !== 8 && index !== 9 &&
          index !== 10 && index !== 11
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.setState({
      ...this.state,
      questionsList: newArray,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  // baltimore handling other case for properyType
  async dynamicHandlingForPropertyType(message:string) {
    let newArray:any = {};
    if (message === 'Institution') {
      const truncatedQuestionsList = this.state.originalQuestionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 6 && index !== 7 &&
          index !== 9 && index !== 11
      );
      newArray = [...truncatedQuestionsList];
    } else if (message === 'Other') {
      const truncatedQuestionsList = this.state.originalQuestionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 6 && index !== 7 &&
          index !== 9 && index !== 10
      );
      newArray = [...truncatedQuestionsList];
    } else {
      const truncatedQuestionsList = this.state.originalQuestionsList.filter(
        (_question, index) => index !== 4 && index !== 5 && index !== 6 && index !== 7 &&
          index !== 10 && index !== 11
      );
      newArray = [...truncatedQuestionsList];
    }
    const newQuestionCount = newArray.length;
    this.slots[this.state.questionsList[this.state.questionNo - 1]['slot']] =
      message;
    let currentQuestion = newArray[newQuestionCount - 1];
    if (typeof currentQuestion != 'undefined') {
      this.setState({
        ...this.state,
        questionsList: newArray,
        questionCount: newQuestionCount,
        questionNo: newQuestionCount,
        intentQuestionsList: newArray.map((elem: any, _index: number) => ({ ...elem }))
      }, async () => {
        let title: string = currentQuestion['title'];
        let translatedTitle: string = currentQuestion['translatedTitle'];
        if (!translatedTitle || title === translatedTitle) {
          if (this.state.sourceLanguageCode !== appLanguageCode) {
            translatedTitle = await services.translateText(title, this.state.sourceLanguageCode)
          } else {
            translatedTitle = title
          }
        }
        this.state.messages.push({
          title: translatedTitle,
          isBot: 1,
          time: getMessageTime(),
        });
        this.setState({ ...this.state }, () => {
          this.chat_space.current.scrollTop = this.chat_space.current.scrollHeight;
        });
      });
    }
  }

  anonymousFlow(message: string) {
    const truncatedQuestionsList = this.state.questionsList.filter(
      (_question, index) => index !== 1 && index !== 2
    );
    const newQuestionCount = truncatedQuestionsList.length;
    this.setState({
      ...this.state,
      questionsList: truncatedQuestionsList,
      questionCount: newQuestionCount
    }, () => {
      this.addNextQuestion(message);
    });
  }

  displayMessageBox = () => {
    return (
      !this.loader &&
      (this.state.intentQuestionsList.length === 0 ||
        (typeof this.state.questionsList[0] != 'undefined' &&
          (this.state.questionsList[this.state.intentQuestionsList.length - 1][
              'type'
              ] === 'input' ||
            this.state.questionsList[this.state.intentQuestionsList.length - 1][
              'type'
              ] === 'address' ||
            this.state.questionsList[this.state.intentQuestionsList.length - 1][
              'type'
              ] === 'email'))) &&
      !this.endConversation
    );
  };

  newConversation = (sameUser: boolean = false) => {
    // this.expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    this.expression =
      /(https?:\/\/(?:www\.|(?!www))[^\s]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    this.urlRegexExpression = /https?:\/\/[\S]*/g;
    this.link = false;
    this.lastQues = false;
    this.dialogHistory = [];
    this.searchResponse = [];
    this.searchResponseList = {};
    this.slots = {};
    this.firstUserReply = '';
    this.loader = false;
    this.lastQues = false;
    this.continueConvQues = false;
    this.showConfirmationMessage = false;
    this.isConfirmationRejected = false;
    this.showConfirmSubmissionMessage = false;
    this.requestConfirmed = false;
    this.contactNo = sameUser ? this.contactNo : '';
    this.intentId = 0;
    this.currentIntent = '';
    this.search = false;
    this.searchString = '';
    this.message_url = '';
    this.validateAddress = 0;
    this.validatePhoneNumber = 0;
    this.validateRoseVilleDate = 0;
    this.endConversation = false;
    this.takeSurvey = false;
    this.startSurvey = false;
    this.showstartSurveyOption = false;
    this.currentSurveyQuestion = 0;
    this.totalSurveyQuestions = 0;
    this.surveyData = [];
    App.disableMessageButton = false;

    sameUser
      ? this.setNewConversationState(CONTINUE_CONVERSATION_GREETING, -1)
      : this.setNewConversationState(
        this.welcomeMessage,
        -2
      );
  };

  setNewConversationState = async(message: string | (string | JSX.Element)[], questionNo: number) => {
    console.log('setNewConversationState trigger')
    if (this.state.sourceLanguageCode !== appLanguageCode) {
      if (typeof message === 'string') {
        message = await services.translateText(message, this.state.sourceLanguageCode)
      } else if (Array.isArray(message) && typeof message[0] === 'string') {
        message = await services.translateText(joinStrings(message as string[]), this.state.sourceLanguageCode)
        message = splitStrings(message)
      }
    }
    this.setState({
      messages: [
        {
          title: message,
          isBot: 1,
        },
      ],
      questionsList: [],
      customMessages: [],
      accountGreetingMessages: [],
      rephraseMessages: [],
      searchResponseArr: [],
      intentQuestionsList: [],
      emergencyIntentResArr: [],
      questionNo: questionNo,
      questionCount: 0,
      type: '',
      showMessageButton: false,
      sourceLanguageCode: this.state.sourceLanguageCode
    });
  };

  getOnSendHandler = () => {
    if (!this.takeSurvey && this.continueConvQues) {
      return this._handleContinueConversationQuestion;
    }

    if (this.takeSurvey && !this.startSurvey) {
      return this._handleSurvey;
    } else {
      return this._addMessage;
    }
  };

  messageBoxHandler = async () => {
    // let message = 'message'
    // if (this.state.sourceLanguageCode !== appLanguageCode) {
    //   message = await services.translateText(message, this.state.sourceLanguageCode)
    // }
    if (!this.takeSurvey && this.continueConvQues) {
      return this._handleContinueConversationQuestion('message');
    } else {
      this._addMessage('message');
    }
  };

  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
    this.checkIsMobileDevice();
    //This part refreshes the chat on reopening chat window from browser
    window.onmessage = (e: MessageEvent) => {
      if (e.data === 'cancel') {
        this.cancelRequest = true;
        services.cancelRequests();
      } else {
        const data = JSON.parse(e.data);
        this.phone_number = data.phone_number;
        this.image_url = data.logo;
        this.chatHeader = data.name;
      }
      this.newConversation(false);
    };

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.divRoot}>
          {/*<img className={classes.headerLogo} src={this.image_url} alt="Citibot"/>*/}
          {!this.state.city_name ? (
            <div className={classes.chatHeaderLoader}>
              <h3 className={classes.header}>
                <Box>
                  <PreLoader/>
                </Box>
              </h3>
            </div>
          ) : (
            <div
              className={classes.chatHeader}
              style={{backgroundColor: this.state.theme}}
            >
              <h3 className={classes.header}>{this.state.city_name}</h3>
            </div>
          )}
          <div
            ref={this.chat_space}
            className={
              this.displayMessageBox() ? classes.chatSpace1 : classes.chatSpace
            }
          >
            <MessageList
              themeColor={this.state.theme}
              messages={this.state.accountGreeting ? this.state.messages : null}
              onChange={(value) => {
              }}
              onSend={this._addMessage}
              labelText={
                this.state.intentQuestionsList[
                this.state.intentQuestionsList.length - 1
                  ]
              }
              loader={this.loader}
              link={this.link}
              haslinks={this.haslinks}
              logo_url={
                this.state.message_icon
                  ? this.state.message_icon
                  : this.state.logo_url
                    ? this.state.logo_url
                    : this.image_url
              }
              message_url={this.message_url}
              searchResponseList={this.searchResponseList}
              searchResponse={this.searchResponse}
              issueAddress={this.issueAddress}
              currentIntent={this.currentIntent}
              currentCity={this.state.account_city.toLowerCase()}
              finalLink={this.finalLink}
              sourceLanguageCode={this.state.sourceLanguageCode}
              appLanguageCode={appLanguageCode}
              city={this.state.account_city.toLowerCase()}
            />
          </div>
          <Spacer/>
          {this.displayMessageBox() ? (
            <div className={classes.messageBox}>
              <MessageBox
                themeColor={this.state.theme}
                isMobileDevice={this.isMobileDevice}
                questionNumber={this.state.questionNo}
                disableMessageButton={App.disableMessageButton}
                removeMessageButton = {this.state.removeMessageButton}
                showMessageButton={
                  this.state.isMessageButtonEnabled ||
                  this.state.showMessageButton
                }
                messageBoxHandler={this.messageBoxHandler}
                onChange={(value) => {
                }}
                onSend={this.getOnSendHandler()}
                labelText={
                  'Type your answer...'
                  // this.state.intentQuestionsList[this.state.intentQuestionsList.length - 1]
                }
                loader={this.loader}
              />
            </div>
          ) : null}
          <div className={classes.footer}>
            <a
              href="http://citibot.io/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSpan1}
            >
              Powered by
            </a>
            <a
              href="http://citibot.io/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.footerSpan}
              style={{color: this.state.theme}}
            >
              <strong>CITIBOT</strong>
            </a>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;

// 1833, 1872,1899 to 2734