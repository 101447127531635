import { NOLA_TREE_SERVICE_TYPE, TREE_ON_POWER_LINE, WEST_HOLLYWOOD } from './messages';

export const getNolaWasterContainerQuestions = (wasteContainerAction: string, wasteType: string) => {
  if (!wasteContainerAction) {
    return [
      {
        title: `What is issue address?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
  wasteContainerAction = wasteContainerAction.toLowerCase();
  if (wasteContainerAction.includes('order')) {
    return [
      {
        title: 'Does your residence consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} cart order?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else if (wasteContainerAction.includes('repair') || wasteContainerAction.includes('repari')) {
    return [
      {
        title: 'What is the nature of damage? (handle, lid, wheels, cracked cart)',
        slot: 'damageDescription',
        type: 'input'
      },
      {
        title: 'Does your residence consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} cart repair?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else {
    return [
      {
        title: 'Does your residence consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} cart replacement?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
};


export const getNolaWasteServiceQuestions = (startStop: string) => {
  return [
    {
      title: 'Does your residence consist of more than 4 units?',
      slot: 'isComplex',
      type: 'input'
    },
    {
      title: `What is the address where you would like to ${startStop} the service?`,
      slot: 'address',
      type: 'address'
    }
  ];
};

export const getNolaPropertyAndDamageTypeNullQuestions = () => {
  return [
    {
      title: 'What is the issue at given address?',
      slot: 'userMessage',
      type: 'input'
    }
  ];
};

export const getNolaWastePickupQuestions = (isBulk: boolean, wasteType: string) => {
  if (isBulk) {
    return [
      {
        title: 'Please describe the bulk item. (Note: For tires, only 4 may be put out at a time.)',
        slot: 'bulkItemDescription',
        type: 'input'
      },
      {
        title: 'Does the property consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} pickup?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
  return [
    {
      title: 'Does the property consist of more than 4 units?',
      slot: 'isComplex',
      type: 'input'
    },
    {
      title: `What is the address for the ${wasteType} pickup?`,
      slot: 'address',
      type: 'address'
    }
  ];
};

export const getNolaTreeIntentQuestions = (treeServiceType: string) => {
  if (!treeServiceType) {
    return [
      {
        title: NOLA_TREE_SERVICE_TYPE,
        slot: 'treeServiceType',
        type: 'single_choice',
        meta: {
          multi: false,
          choices: ['tree service', 'stump removal', 'park maintenance']
        }
      }
    ];
  }
  treeServiceType = treeServiceType.toLowerCase();
  if (treeServiceType.includes('tree service')) {
    return [
      {
        title: TREE_ON_POWER_LINE,
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: `Are you the property owner?`,
        slot: 'isPropertyOwner',
        type: 'input'
      },
      {
        title: `Are the roots buckling sidewalk, under house, etc?`,
        slot: 'sidewalkBuckled',
        type: 'input'
      },
      {
        title: 'Please indicate which one of the following descriptions applies to your request: ' +
          'tree branches falling, tree is leaning, tree removal/dead tree, tree trim or stump grinding',
        slot: 'treeIssue',
        type: 'input'
      },
      {
        title: `What is the numerical address where you need the tree service?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else if (treeServiceType.includes('stump removal')) {
    return [
      {
        title: 'What is the numerical address where you need the stump removal?',
        slot: 'address',
        type: 'address'
      }
    ];
  } else {
    return [
      {
        title: `Is this a New Orleans Recreation Development Commission (NORDC) park or facility?`,
        slot: 'isNordc',
        type: 'input'
      },
      {
        title: `What is the numerical address where you need the park maintenance?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
};

export const PEST_CONTROL_MESSAGE = (pestType: string) => `For ${pestType} issues. Please contact 311 directly.`;

export const getNolaFloodIntentQuestions = (floodingType: string) => {
  if (!floodingType) {
    return [
      {
        title: 'Is the street flooded?',
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: 'Is this an issue with a catch basin or flooding?',
        slot: 'floodingType',
        type: 'input'
      },
      {
        title: 'Is this an issue with a ditch?',
        slot: 'isDitch',
        type: 'input'
      },
      {
        title: `What is the address of the issue?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }

  floodingType = floodingType.toLowerCase();

  if (floodingType.includes('flooding')) {
    return [
      {
        title: 'Is the street flooded?',
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: `What is the address of the flooding?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else if (floodingType.includes('culvert')) {
    return [
      {
        title: 'Is the street flooded?',
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: `What is the issue with the culvert (IE. clogged, damaged)?`,
        slot: 'drainIssue',
        type: 'input'
      },
      {
        title: `What is the address of the culvert?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else if (floodingType.includes('catch basin')) {
    return [
      {
        title: 'Is the street flooded?',
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: `What is the issue with the catch basin (IE. clogged, damaged)?`,
        slot: 'drainIssue',
        type: 'input'
      },
      {
        title: `What is the address of the catch basin?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else {
    return [
      {
        title: 'Is the street flooded?',
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: `What is the address of the issue?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
};

export const getParkingPermitIntentFlowQuestions = (account_city: string) => {
  const permitTypeChoices = ['Residential', 'Guest'];

  if (account_city !== WEST_HOLLYWOOD) {
    permitTypeChoices.push('Moving');
  }

  return [
    {
      title: 'Do you currently have or have you had a residential parking permit in the last 6 months?',
      slot: 'have_parking_permit',
      type: 'binary',
      meta: {
        accept_text: 'Yes',
        reject_text: 'No'
      }
    },
    {
      title: 'Please tell us the type of parking permit you need. Choose one of the following:',
      slot: 'parking_permit_type',
      type: 'single_choice',
      meta: {
        multi: false,
        choices: permitTypeChoices
      }
    },
    {
      title: 'What type of permit do you need for your guest (Annual Guest or Permit-by-Plate Visitor/Daily Visitor)',
      slot: 'guest_parking_permit_type',
      type: 'single_choice',
      meta: {
        multi: false,
        choices: ['Annual Guest', 'Permit by Plate']
      }
    }
  ];
};