import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import classes from "./MessageCard.module.css";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
// @ts-ignore
import Speech from "speak-tts";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Opts } from 'linkifyjs';
import Linkify from "linkify-react";
import {
  OAKLAND,
  SEARCH_CARD_MESSAGE,
  VISIT_LINK_MESSAGE,
  COLORADO_SEARCH_CARD_MESSAGE
} from "../../utils/messages";
import { services } from "../../Services/services";
import { joinStrings, splitStrings } from "../../utils/string.util";

const GRAY_BLURB_COLOR = "#3A3A3C";
const LIGHT_GRAY_BLURB_COLOR = "#E9E9EB";

interface MessageCardProps {
  themeColor: string;
  isMine: boolean;
  isFirstBotMessage: boolean;
  message: string;
  messageTime: string;
  loader: boolean;
  link: boolean;
  haslinks: boolean;
  logo_url: any;
  message_url: string;
  issueAddress: string;
  searchResponse: { title: string; link: string }[];
  currentIntent: string;
  currentCity: string;
  finalLink: string;
  sourceLanguageCode: string;
  appLanguageCode: string;
  city: string
}

export const MessageCard: React.FC<MessageCardProps> = (
  props: MessageCardProps
) => {
  const { sourceLanguageCode, appLanguageCode, searchResponse, message, city } =
    props;
  const [searchCardMessage, setSearchCardMessage] =
    useState(SEARCH_CARD_MESSAGE);
  const [coloradoSearchCardMessage, setColoradoSearchCardMessage] = useState(COLORADO_SEARCH_CARD_MESSAGE);  
  const [appTranslatedMessage, setAppTranslatedMessage] = useState(message);
  const [searchResponseTitles, setSearchResponseTitles] = useState(
    searchResponse && Array.isArray(searchResponse) ? searchResponse.map((elem) => elem.title ) : []
  );

  const [speechReady, setSpeechReady] = React.useState(false);

  const speech = new Speech();
  speech
    .init({
      volume: 1,
      lang: "en-US",
      rate: 1,
      pitch: 1,
      voice: "Google US English",
      splitSentences: true,
    })
    .then((data: any) => {
      setSpeechReady(true);
    })
    .catch((e: any) => {
      setSpeechReady(false);
    });
  const translateText = useCallback(async (text: string, langCode: string) => {
    try {
      const translatedResponse = await services.translateText(text, langCode);
      return translatedResponse;
    } catch (err) {
      console.error("MessageCard error while translating text =>", err);
      return text;
    }
  }, []);
  const translate = useCallback(async () => {
    try {
      const newSearchResponses = searchResponse || [] ;
      if (city === 'colorado springs') {
        const coloradoSourceTranslationRes = await translateText(
          joinStrings([
            COLORADO_SEARCH_CARD_MESSAGE,
            ...newSearchResponses.map((elem) => elem.title)
          ]),
          sourceLanguageCode
        );
        const [newSearchCardMessage, ...newSearchResponseTitles] = splitStrings(
          coloradoSourceTranslationRes
        );
        const newAppTranslatedMessage = await translateText(
          message,
          appLanguageCode
        );
        setColoradoSearchCardMessage(newSearchCardMessage);
        setSearchResponseTitles(newSearchResponseTitles);
        setAppTranslatedMessage(newAppTranslatedMessage);
      } else {
        const sourceTranslationResponse = await translateText(
          joinStrings([
            SEARCH_CARD_MESSAGE,
            ...newSearchResponses.map((elem) => elem.title),
          ]),
          sourceLanguageCode
        );
        const [newSearchCardMessage, ...newSearchResponseTitles] = splitStrings(
          sourceTranslationResponse
        );
        const newAppTranslatedMessage = await translateText(
          message,
          appLanguageCode
        );
        setSearchCardMessage(newSearchCardMessage);
        setAppTranslatedMessage(newAppTranslatedMessage);
        setSearchResponseTitles(newSearchResponseTitles);
      }
    } catch (err) {
      console.error(
        "MessageCard error while translate SEARCH_CARD_MESSAGE  searchResponse =>",
        err
      );
    }
  }, [
    setSearchCardMessage,
    setAppTranslatedMessage,
    setSearchResponseTitles,
    translateText,
    sourceLanguageCode,
    appLanguageCode,
    message,
    searchResponse,
    city
  ]);
  useEffect(() => {
    if (sourceLanguageCode !== appLanguageCode) {
      translate();
    }
  }, [sourceLanguageCode, appLanguageCode, translate]);

  const speak = (data: string) => {
    if (speech.speaking()) {
      stop();
    }
    speech
      .speak({
        text: data,
      })
      .then(() => {
        console.log("Success !");
      })
      .catch((e: any) => {
        console.error("An error occurred :", e);
      });
  };

  const stop = () => {
    speech.cancel();
    console.log(speechReady);
  };

  const configureQuestionMessage = useCallback(function() {
    // App.disableMessageButton = false;
    let question = message;
    if (question.includes("{address}")) {
      question = question.replace("{address}", props.issueAddress);
    }
    return question;
  }, [message, props.issueAddress])
  
  const specialSearch = useMemo(() => {
    //check for special search response flow for Oakland
    return props.currentCity.toLowerCase() === OAKLAND
  }, [props.currentCity]);

  const questionMessage = useMemo(()=>{
    return configureQuestionMessage()
  }, [configureQuestionMessage])

  const linkifyOption: Opts = useMemo(()=>{
    return {
      defaultProtocol: 'http',
      target: '_blank',
      rel: 'noopener',
      className: props.isMine ? 'message_link_user' : 'message_link_bot'
    }
  }, [props.isMine])

  return (
    <div className={classes.messageBubble}>
      <Box
        display="flex"
        flexDirection={props.isMine ? "row-reverse" : "row"}
        alignItems={"end"}
        width={"100%"}
      >
        <img
          className={props.isMine ? classes.iconUser : classes.iconBot}
          src={props.isMine ? "/user.svg" : props.logo_url}
          alt="citibot webchat"
        />
        <div
          className={
            props.isMine
              ? classes.messageContainerUser
              : props.isFirstBotMessage
              ? classes.messageContainerBotFirst
              : classes.messageContainerBot
          }
        >
          <div
            className={
              props.isMine ? classes.textColorUser : classes.textColorBot
            }
            style={{
              background: props.isMine
                ? LIGHT_GRAY_BLURB_COLOR
                : props.themeColor,
            }}
          >
            <Linkify as="span" options={linkifyOption}>
              {questionMessage}
            </Linkify>
          </div>
          {props.isMine ? (
            <div className={classes.userMessageTime}>{props.messageTime}</div>
          ) : null}
        </div>
        {!props.isMine && speech.hasBrowserSupport() ? (
          <IconButton
            style={{ paddingTop: "0" }}
            onClick={() => {
              speak(questionMessage);
            }}
          >
            <VolumeUpIcon color="primary" style={{ color: props.themeColor }} />
          </IconButton>
        ) : null}
      </Box>

      {props.searchResponse ? (
        <div className={classes.linkContainer}>
          {props.searchResponse.slice(0, 5).map(
            (
              item: {
                title: string;
                link: string;
              },
              index
            ) => {
              return (
                <Box
                  display="flex"
                  alignItems={"flex-start"}
                  width={"100%"}
                  className={classes.links}
                  key={index}
                >
                  <Button
                    className={classes.linkButton}
                    style={{
                      color: GRAY_BLURB_COLOR,
                      border: `2px solid ${GRAY_BLURB_COLOR}`,
                    }}
                    variant="outlined"
                    color="primary"
                    href={item.link}
                    target={"_blank"}
                    rel="noopener"
                  >
                    {searchResponseTitles[index] || item.title}
                    <OpenInNewIcon style={{ color: GRAY_BLURB_COLOR }} />
                  </Button>
                </Box>
              );
            }
          )}
          {/*{*/}
          {/*    <Box display="flex" alignItems={'flex-start'}*/}
          {/*         width={'100%'} className={classes.link} key={1}>*/}
          {/*        <Button variant="outlined" color="primary" href={props.message_url[0]} target={"_blank"}*/}
          {/*                rel="noopener">*/}
          {/*            {"Visit this link for more results"}<OpenInNewIcon color="primary"/>*/}
          {/*        </Button>*/}
          {/*    </Box>*/}
          {/*}*/}
          {/*<Card>*/}
          {/*    <CardContent>*/}
          {/*        <span className={classes.textColorBot}>{SEARCH_CARD_MESSAGE}</span>*/}
          {/*    </CardContent>*/}
          {/*</Card>*/}
          {!specialSearch &&
          props.finalLink === props.searchResponse[0].link && props.city !== 'roseville' ? (
            <div
              className={`${classes.textColorBot}`}
              style={{ marginTop: "20px", background: props.themeColor, display: (props.currentIntent === 'cb_message_intent') ? 'none' : 'block' }}
            >
              {
              city === 'colorado springs' ? coloradoSearchCardMessage  : searchCardMessage
              }
            </div>
          ) : null}
        </div>
      ) : null}
      {!props.haslinks &&
      !props.loader &&
      props.link &&
      appTranslatedMessage === VISIT_LINK_MESSAGE ? (
        <div className={classes.linkContainer}>
          <Box
            display="flex"
            alignItems={"center"}
            width={"100%"}
            className={classes.link}
          >
            <Button
              variant="outlined"
              color="primary"
              href={props.message_url[0]}
              target={"_blank"}
              rel="noopener"
            >
              {"Visit this link to complete your search"}
              <OpenInNewIcon color="primary" />
            </Button>
          </Box>
          <Card>
            <CardContent>
              <span
                className={classes.textColorBot}
                style={{ background: props.themeColor, display: (props.currentIntent === 'cb_message_intent') ? 'none' : 'block' }}
              >
                {city === 'colorado springs'  ? coloradoSearchCardMessage : searchCardMessage}
              </span>
            </CardContent>
          </Card>
        </div>
      ) : null}
    </div>
  );
};
